import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hiddenField'];

  changeHiddenFieldValue(event) {
    const hiddenFieldValue = event.currentTarget.dataset.valueForHiddenField;

    this.hiddenFieldTarget.value = hiddenFieldValue;
    this.triggerHiddenFieldChangeEvent(this.hiddenFieldTarget);
  }

  triggerHiddenFieldChangeEvent(hiddenField) {
    const customEvent = new Event('change');
    hiddenField.dispatchEvent(customEvent);
  }
}
