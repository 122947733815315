import { Controller } from "stimulus"

export default class extends Controller {
  turbolinks_android(event) {
    event.preventDefault()

    Turbolinks.visit('/turbolinks/back')
  }

  turbolinks_ios(event) {
    event.preventDefault()

    Turbolinks.visit('/turbolinks/back')
  }

  other(event) {
    event.preventDefault()

    window.history.back()
  }
}


