import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'dailyRecurrenceRuleFields',
    'weeklyRecurrenceRuleFields',
    'doesEndOnDateFields'
  ];

  DAILY = 'daily';
  WEEKLY = 'weekly';

  handleRuleTypeSelect(event) {
    const ruleType = event.target.value.toLowerCase();

    switch (ruleType) {
      case this.DAILY:
        this.showDailyRecurrenceRuleFields();
        this.hideWeeklyRecurrenceRuleFields();
        this.showDoesEndOnDateFields();
        break;
      case this.WEEKLY:
        this.showWeeklyRecurrenceRuleFields();
        this.hideDailyRecurrenceRuleFields();
        this.showDoesEndOnDateFields();
        break;
      default:
        this.clearRecurrenceRuleFields();
        break;
    }
  }
  showDailyRecurrenceRuleFields() {
    this.show(this.dailyRecurrenceRuleFieldsTarget);
  }

  hideDailyRecurrenceRuleFields() {
    this.hide(this.dailyRecurrenceRuleFieldsTarget);
  }

  showWeeklyRecurrenceRuleFields() {
    this.show(this.weeklyRecurrenceRuleFieldsTarget);
  }

  hideWeeklyRecurrenceRuleFields() {
    this.hide(this.weeklyRecurrenceRuleFieldsTarget);
  }

  showDoesEndOnDateFields() {
    this.show(this.doesEndOnDateFieldsTarget);
  }

  hideDoesEndOnDateFields() {
    this.hide(this.doesEndOnDateFieldsTarget);
  }

  show(element) {
    element.classList.remove('hidden');
  }

  hide(element) {
    element.classList.add('hidden');
  }

  clearRecurrenceRuleFields() {
    this.hideDailyRecurrenceRuleFields();
    this.hideWeeklyRecurrenceRuleFields();
    this.hideDoesEndOnDateFields();
  }
}
