import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['option']

  filter(e) {
    const name = e.target.value
    if (name === '') {
      this.optionTargets.forEach(option => {
        option.classList.remove(this.data.get("class"))
      })
    } else {
      this.optionTargets.forEach(option => {
        if (option.dataset.tagName.toLowerCase().includes(name.toLowerCase())) {
          option.classList.remove(this.data.get("class"))
        } else {
          option.classList.add(this.data.get("class"))
        }
      })
    }
  }
}
