import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  initialize() {
    this.locale = this.data.get('locale');
  }

  connect = () => {
    const currentDate = new Date().toLocaleDateString(this.locale, {
      day: 'numeric',
      month: 'long',
    });
    this.fieldTargets.forEach(field => field.value = currentDate);
  }
}
