import { Controller } from "stimulus"
import ApexCharts from "apexcharts"

export default class extends Controller {
  static targets = ['renderWrapper', 'preloader']

  initialize() {
    this.time = 5000;
    this.timeoutId = null;
    this.chart = this.renderWrapperTarget.firstElementChild;
    this.chartId = this.chart.id;
    this.url = this.data.get('url');
  }

  connect(){
    if (this.url) this.startPolling();
  }

  disconnect() {
    ApexCharts.exec(this.chartId, 'destroy');

    if (this.url) this.stopPolling();
  }

  startPolling = () => {
    this.scheduleUpdate();
    document.addEventListener("visibilitychange", this.togglePolling);
  }

  stopPolling = () => {
    clearTimeout(this.timeoutId);
    document.removeEventListener("visibilitychange", this.togglePolling);
  }

  fetchData = () => {
    Rails.ajax({
      url: this.url,
      type: "get",
      dataType: 'json',
      success: (response) => {
        this.updateSeries(response.chart_data.series);
        this.scheduleUpdate();
      },
      error: (error) => {
        console.error(error);
        this.stopPolling();
      }
    })
  }

  updateSeries = (series) => {
    ApexCharts.exec(this.renderWrapperTarget.firstElementChild.id, 'updateSeries', series )
  }

  updateSeriesOnAjaxSuccess = (response) => {
    this.updateSeries(response.detail[0].chart_data.series)
}

  showPreloader(){ this.preloaderTarget.classList.toggle('opacity-0') }
  hidePreloader(){ this.preloaderTarget.classList.toggle('opacity-0') }

  scheduleUpdate = () => {
    this.timeoutId = setTimeout(this.fetchData, this.time);
  }

  togglePolling = () => {
    if (document.hidden) {
      clearTimeout(this.timeoutId);
    } else  {
      this.fetchData(this.url, this.chartId);
    }
  }
}
