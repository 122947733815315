import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    formPrefix: String,
  }
  static targets = [ "list" ]

  connect() {
    new window.Sortable(this.listTarget, {
      handle: ".drag-and-drop-handle",
      animation: 250,
      easing: "cubic-bezier(0.33, 1, 0.68, 1)",
      ghostClass: "drag-and-drop-ghost-class",
      dragClass: "drag-and-drop-drag-class",
      onEnd: () => {
        this.updateItemIndexes()
      },
    })
  }

  updateItemIndexes() {
    const inputSelector = `input[name^="${this.formPrefixValue}[inspection_template_questions_attributes]"]`
    const labelSelector = `label[for^="${this.formPrefixValue}_inspection_template_questions_attributes"]`
    const selectSelector = `select[id^="${this.formPrefixValue}_inspection_template_questions_attributes"]`
    const orderInputSelector = `input[name^="${this.formPrefixValue}[inspection_template_questions_attributes]"][name$="[order]"]`

    const forRegex = /(.*\_)(\d+)(\_.*)/
    const idRegex = forRegex
    const nameRegex = /(.*\[)(\d+)(\].*)/

    let items = [ ... this.listTarget.children ]
    items.forEach((item, index) => {
      item.dataset.questionOrder = index

      let inputs = item.querySelectorAll(inputSelector)
      let labels = item.querySelectorAll(labelSelector)
      let selects = item.querySelectorAll(selectSelector)
      let orderInput = item.querySelector(orderInputSelector)

      orderInput.value = index

      inputs.forEach(input => {
        input.name = input.name.replace(nameRegex, `$1${index}$3`)
        input.id = input.id.replace(idRegex, `$1${index}$3`)
      })

      labels.forEach(label => {
        label.htmlFor = label.htmlFor.replace(forRegex, `$1${index}$3`)
      })

      selects.forEach(select => {
        select.name = select.name.replace(nameRegex, `$1${index}$3`)
        select.id = select.id.replace(idRegex, `$1${index}$3`)
      })
    })
  }
}
