import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'button' ];

  reset(event) {
    event.preventDefault()
    this.buttonTargets.forEach(button => {
      button.checked = false
    });
  }
}
