import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'header', 'confirmModal'];

  populateContent(event) {
    event.stopPropagation();

    const path = event.currentTarget.dataset.path;

    Rails.ajax({
      url: path,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.containerTarget.innerHTML = data.details;
        this.headerTarget.innerHTML = data.header;
        this.confirmModalTarget.innerHTML = data.confirm_modal_content;
      }
    });
  }
}
