import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    openUrl: String,
    allowBackgroundClose: Boolean
  }

  connect() {
    let modalContainer = document.getElementById('root-modal-container');
    if (modalContainer.innerHTML !== '')
      document.body.classList.add('fixed');
  }

  open(event) {
    event.preventDefault()
    let modalContainer = document.getElementById('root-modal-container');

    Rails.ajax({
      url: this.openUrlValue,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        modalContainer.innerHTML = data;
        document.body.classList.add('fixed');
      }
    });
  }

  close() {
    let modalContainer = document.getElementById('root-modal-container');
    modalContainer.innerHTML = ""
    document.body.classList.remove('fixed');
  }
  clickBackground(e) {
    if (this.allowBackgroundCloseValue && e.target.classList.contains('modal-container')) {
      let modalContainer = document.getElementById('root-modal-container');
      modalContainer.innerHTML = ""
    }
  }
}
