import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['content', 'success', 'message'];

  initialize() {
    this.hiddenClass = 'hidden';
    this.intervalPeriods = this.messageTargets.length + 1;
    this.intervalTime = 1000;
    this.initialDelay = 700;
    this.postUrl = this.data.get('postUrl');
    this.successUrl = this.data.get('successUrl');
  }

  connect() {
    this.successTarget.classList.add(this.hiddenClass);
  }

  sendRequest() {
    Rails.ajax({
      url: this.postUrl,
      type: "post",
      dataType: 'json',
      success: () => {
        this.hideContent();
        this.showSuccess();
        this.redirect();
      },
      error: ({ flash }) => {
        this.displayFlash(flash);
      }
    })
  }

  hideContent() {
    this.contentTarget.classList.add(this.hiddenClass);
  }

  showSuccess() {
    this.successTarget.classList.remove(this.hiddenClass);
    this.messageTargets.forEach((message, index) => {
      setTimeout(() =>{
        message.classList.remove('invisible');
        message.classList.add('animated', 'fadeIn');
      }, this.delayInterval(index));
    })
  }

  delayInterval(number) {
    return (number * this.intervalTime) + this.initialDelay;
  }

  redirect() {
    Turbolinks.clearCache();
    setTimeout(() => Turbolinks.visit(this.successUrl, { action: "replace" }), this.delayInterval(this.intervalPeriods));
  }

  displayFlash(flash) {
    let flashContainer = document.getElementById('flashContainer');
    flashContainer.insertAdjacentHTML('afterbegin', flash);
  }
}
