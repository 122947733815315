import { Controller } from "stimulus";
import { getClosest } from "./get_closest";

export default class extends Controller {

  toggleCollapse(event) {
    const el = event.target;

    const accordionEl = "js-expansion-panel";
    const accordionContentEl = "js-expansion-panel-content";
    const accordionContentState = "active";
    const accordion = getClosest(el, '.' + accordionEl);
    const accordionContent = accordion.querySelector('.' + accordionContentEl);
    const isCollapsed = accordionContent.getAttribute('data-collapsed') === 'true';

    this.toggleExpandIcon(accordion);

    if (isCollapsed) {
      accordion.classList.add(accordionContentState);
      accordionContent.classList.add(accordionContentState);
      this.expandSection(accordionContent);
      accordionContent.setAttribute('data-collapsed', 'false');
    } else {
      accordion.classList.remove(accordionContentState);
      accordionContent.classList.remove(accordionContentState);
      this.collapseSection(accordionContent);
    }
  }

  collapseSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    const sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    const elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function () {
        element.style.height = 0 + 'px';
      });
    });

    // mark the section as "currently collapsed"
    element.setAttribute('data-collapsed', 'true');
  }

  expandSection(element) {
    // get the height of the element's inner content, regardless of its actual size
    const sectionHeight = element.scrollHeight;

    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 'px';

    // when the next css transition finishes (which should be the one we just triggered)
    element.addEventListener("transitionend", function handler() {
      // remove this event listener so it only gets triggered once
      element.removeEventListener("transitionend", handler);
      // remove "height" from the element's inline styles, so it can return to its initial value
      element.removeAttribute("style");
    });

    // mark the section as "currently not collapsed"
    element.setAttribute('data-collapsed', 'false');
  }

  toggleExpandIcon(el) {
    const expandLessClass = el.querySelector('.js-icon-expand-less');
    const expandMoreClass = el.querySelector('.js-icon-expand-more');

    expandLessClass.classList.toggle("hidden");
    expandMoreClass.classList.toggle("hidden");
  }
}
