import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  initialize() {
    this.formId = 'frequency-filters-form';
  }

  apply(event) {
    this.toggleSelectedFilter(event.target.dataset.keyword);
    this.applyAllSelectedFilters();
  }

  toggleSelectedFilter(filter) {
    const targetCheckbox = this.inputTargets.find(el => el.value == filter);
    targetCheckbox.checked = !targetCheckbox.checked;
  }

  applyAllSelectedFilters() {
    const form = document.getElementById(this.formId);
    this.application.getControllerForElementAndIdentifier(form, 'form').submit();
  }
}

