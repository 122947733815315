import { Controller } from 'stimulus'
import { compressImage } from 'src/helpers'

export default class extends Controller {
  static targets = ['fileUpload', 'commentList'];
  static values = {
    maxSize: Number,
    maxDimensions: Number,
    createCommentPath: String
  }

  async handleFileUploadSelected(event) {
    const attachedImages = event.target.files;
    if (!attachedImages || attachedImages.length === 0) { return }

    this.commentId = event.currentTarget.dataset.commentId

    if (this.commentId == undefined) {
      const { comment_id, edit_comment_form: { id: editCommentFormId, element: editCommentFormElement } } = await this.initializeDraftComment();
      this.appendEditCommentForm(editCommentFormId, editCommentFormElement);
      this.commentId = comment_id
    }

    this.createAnswerPhotoPath = event.target.dataset.uploadPath.replace(':id', this.commentId)

    Array.from(attachedImages).forEach(async (attachedImage) => {
      const filename = attachedImage.name
      const newImageId = filename.replaceAll(" ", "") + Date.now().toString();

      const compressedImage = await this.compress(attachedImage);
      this.fileUploadTarget.disabled = true;

      this.insertLoadingImage(newImageId)

      this.upload(compressedImage, filename).then((imageAttachment) => {
        this.replaceLoadingImageWithImageAttachment(newImageId, imageAttachment);
        this.reset();
      }).catch((error) => {
        this.addErrorMessage(error.alert);
        this.reset();
        this.removeLoadingImage(newImageId);
      })
    })
  }

  compress(image) {
    const maxSize = Number(this.maxSizeValue);
    const maxDimension = Number(this.maxDimensionValue);

    const options = {
      maxSize,
      maxDimension,
    }

    return new Promise((resolve, reject) => {
      compressImage(image, options)
        .then(compressedImage => resolve(compressedImage))
        .catch(error => reject(error));
    });
  }

  upload(image, filename) {
    const formData = new FormData();

    formData.append('create_inspection_answer_comment_photo[image]', image, filename)

    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: this.createAnswerPhotoPath,
        type: 'POST',
        dataType: 'json',
        data: formData,
        success: (data) => resolve(data.image_attachment_component),
        error: (error) => reject(error)
      });
    })
  }

  insertLoadingImage(id) {
    const element = `<div id=${id}>${this.data.get('loadingImage')}</div>`
    const loadingImageListElement = document.getElementById(`inspection_answer_comment_${this.commentId}_image_attachment_list`);
    loadingImageListElement.insertAdjacentHTML('beforeend', element);
  }

  removeLoadingImage(id) {
    document.getElementbyId(id).remove();
  }

  replaceLoadingImageWithImageAttachment(id, imageAttachment) {
    document.getElementById(id).outerHTML = String(imageAttachment)
  }

  reset() {
    this.fileUploadTarget.value = "";
    this.fileUploadTarget.disabled = false;
  }

  initializeDraftComment() {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: this.createCommentPathValue,
        type: 'POST',
        dataType: 'json',
        success: data => resolve(data),
        error: error => reject(error)
      })
    })
  }

  appendEditCommentForm(formId, formElement) {
    if (document.getElementById(formId)) return

    this.commentListTarget.insertAdjacentHTML('beforeend', formElement)
  }

  handleCommentClick(event) {
    event.preventDefault();

    Rails.ajax({
      url: this.createCommentPathValue,
      type: 'POST',
      dataType: 'json',
      success: (data) => {
        if (!document.getElementById(data.edit_comment_form.id)) {
          this.commentListTarget.insertAdjacentHTML('beforeend', data.edit_comment_form.element)
        }
        document.getElementById(data.edit_comment_form.id).querySelector('textarea').focus();
      }
    })
  }

  handleCommentFormCancelClick(event) {
    event.preventDefault();
    const { commentPath, formComponentId, isPublished } = event.currentTarget.dataset;
    const formComponent = document.getElementById(formComponentId)

    if (isPublished == 'true') {
      Rails.ajax({
        url: commentPath,
        type: 'GET',
        dataType: 'json',
        success: (data) => {
          formComponent.outerHTML = data.comment_element
        }
      })
    } else {
      formComponent.outerHTML = ''
    }
  }

  handleEditCommentClick(event) {
    event.preventDefault();
    Rails.ajax({
      url: event.currentTarget.dataset.editCommentPath,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        document.getElementById(data.comment_element_id).outerHTML = data.edit_comment_form
        document.getElementById(data.edit_comment_form_id).querySelector('textarea').focus();
      }
    })
  }

  handleDeleteCommentClick(event) {
    event.preventDefault();

    Rails.ajax({
      url: event.currentTarget.dataset.commentPath,
      type: 'DELETE',
      dataType: 'json',
      success: (data) => {
        document.getElementById(data.comment_element_id).remove();
        const domManipulationController = this.application.getControllerForElementAndIdentifier(this.element, 'dom-manipulation')
        domManipulationController.appendContent({ detail: [data] });
      }
    })
  }

  disableFileUpload() {
    this.fileUploadTarget.disabled = true;
  }

  enableFileUpload() {
    this.fileUploadTarget.disabled = false;
  }
}


