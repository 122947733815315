import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'createForm' ];
  static values = {
    createButton: String
  }

  handleCancelClick() {
    this.closeCreateForm()
    this.appendCreateButton()
  }

  closeCreateForm() {
    const createForm = this.createFormTarget
    if (!createForm) return

    createForm.remove()
  }

  appendCreateButton() {
    const createSectionTarget = document.getElementById('create_section')
    if (!createSectionTarget) return

    createSectionTarget.innerHTML = this.createButtonValue
  }
}
