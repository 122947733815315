import { Controller } from 'stimulus';
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = ['entries', 'pagination', 'workerList'];

  initialize() {
    this.scroll = debounce(this.scroll, 200)
  }

  reachTheEndOfContainer(container) {
    return container.scrollTop + container.offsetHeight + 16 >= container.scrollHeight;
  }

  scroll(event) {
    if (!this.hasPaginationTarget) { return; }
    const nextPage = this.paginationTarget.querySelector("a[rel='next']");
    if (!nextPage) { return; }

    const nextPageUrl = nextPage.href;

    if (this.reachTheEndOfContainer(event.target)) {
      this.loadMore(nextPageUrl);
    }
  }

  loadMore(nextPageUrl) {
    Rails.ajax({
      type: 'GET',
      url: nextPageUrl,
      dataType: 'json',
      success: (data) => {
        this.workerListTarget.insertAdjacentHTML('beforeend', data.workers);
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination;
        this.application.getControllerForElementAndIdentifier(document.getElementById('calendar-scroll-panel'), 'scout--schedules--calendar').initCalendarDaily()
      }
    });
  }
}
