import Turbolinks from 'turbolinks';

Turbolinks.scroll = {};

document.addEventListener("turbolinks:load", ()=> {

  const elements = document.querySelectorAll("[data-turbolinks-scroll]");
  const scrollContainer = document.getElementById('scroll-persistence-container') || document.scrollingElement;

  elements.forEach(function(element){

    element.addEventListener("click", ()=> {
      Turbolinks.scroll['top'] = scrollContainer.scrollTop;
    });

    element.addEventListener("submit", ()=> {
      Turbolinks.scroll['top'] = scrollContainer.scrollTop;
    });

  });

  if (Turbolinks.scroll['top']) {
    scrollContainer.scrollTo(0, Turbolinks.scroll['top']);
  }

  Turbolinks.scroll = {};
});
