import { Controller } from 'stimulus';
// Set flag -> clearSelectedSites() is skipped if the first time that the 'change' event occurs
let isSkipped = true;

export default class extends Controller {
  static targets = [
    'clientSelectInput',
    'siteSelectInput',
    'siteSelect'
  ]

  static values = {
    path: String,
    setDisplayTextFieldAttributes: Object
  };

  initialize() {
    isSkipped = true
  }

  connect() {
    // TODO SOMETHING
    this.fetchSiteOptions()
  }

  fetchSiteOptions() {
    const element = this.siteSelectTarget.children[0]
    const clientId = this.clientSelectInputTarget.value
    const dataSourceUrl = this.pathValue
    const dataSourceUrlParams = {
      client_organization_id: clientId
    }

    element.setAttribute("data-seeker--lazyload-data-source-url-value", dataSourceUrl)
    element.setAttribute("data-seeker--lazyload-params-value", JSON.stringify(dataSourceUrlParams))
  }

  handleClientChange(e) {
    if (!isSkipped) this.clearSelectedSites(e)
    isSkipped = false
    this.fetchSiteOptions()
  }

  clearSelectedSites(e) {
    this.siteSelectInputTarget.value = ""

    const element = this.siteSelectTarget.children[0]
    element.setAttribute("data-seeker--lazyload-selected-options", "[]")

    const chipElements = [...element.getElementsByClassName("chip-el")]
    chipElements.forEach(chip => chip.remove())
    this.handleDisplayTextField(e)
  }

  handleDisplayTextField(e) {
    const { identifier, controller, method } = this.setDisplayTextFieldAttributesValue;
    const formComponent = document.getElementById(identifier);

    if (formComponent) {
      this.application.getControllerForElementAndIdentifier(formComponent, controller).execute(method, e)
    }
  }
}
