import { Controller } from 'stimulus';
import moment from "moment";

export default class extends Controller {

  static targets = [
    'finishDate',
    'isOvernight',
    'startDateInput',
    'finishDateInput',
    'startTimeInput',
    'finishTimeInput',
    'clientSelectInput',
    'siteSelect',
    'siteSelectInput',
    'accountWorkerSelect',
    'accountWorkerSelectInput'
  ]

  static values = {
    path: String
  };

  DATE_FORMAT = 'YYYY-MM-DD';
  TIME_FORMAT = 'HH-mm';

  connect() {
    if (this.isShiftOverNight()) {
      this.showFinishDate();
    }

    if (this.selectedClient()) {
      this.showSiteDropdown();
    } else {
      this.hideSiteDropdown();
    }

    this.focusOnError();
  }

  adjustFinishDate() {
    let startDate = this.startDateInputTarget.value
    if (this.isShiftOverNight()) {
      this.adjustDate(this.finishDateInputTarget, startDate, 1)
    } else {
      this.adjustDate(this.finishDateInputTarget, startDate)
    }
  }

  adjustFinishTime() {
    let startTime = this.startTimeInputTarget.value
    this.adjustTime(this.finishTimeInputTarget, startTime, 1)
  }

  isShiftOverNight() {
    return !!this.isOvernightTarget.checked
  }

  handleCheck() {
    this.isShiftOverNight() ? this.showFinishDate() : this.hideFinishDate()
  }

  showFinishDate() {
    this.show(this.finishDateTarget)
    this.adjustFinishDateFromStartDate()
  }

  hideFinishDate() {
    this.hide(this.finishDateTarget)
    this.adjustFinishDateFromStartDate()
  }

  adjustFinishDateFromStartDate() {
    let startDate = this.startDateInputTarget.value
    let amount = this.isShiftOverNight() ? 1 : 0
    this.adjustDate(this.finishDateInputTarget, startDate, amount)
  }

  adjustDate(element, date, amount = 0) {
    if (!date || !element?._flatpickr) return;

    element._flatpickr.setDate(moment(date).add(amount, 'day').format(this.DATE_FORMAT))
  }

  adjustTime(element, time, amount = 0) {
    if (!time) return;
    element._flatpickr.setDate(moment(time, 'hh:mm').add(amount, 'hour').format(this.TIME_FORMAT))
  }

  focusOnError() {
    let errorElement = this.element.querySelector('p.error[error-for]')
    if (!errorElement) return;

    let errorFieldNames = errorElement.getAttribute('error-for').split(' ')

    for (const name of errorFieldNames) {
      let elm = this.element.querySelector(`input[name='${name}']:not([type=hidden]):not(.hidden)`)
      if (elm) elm.focus();
    }
  }

  show(element) {
    element.classList.remove('hidden')
  }

  hide(element) {
    element.classList.add('hidden')
  }

  selectedClient() {
    return !!this.clientSelectInputTarget.value
  }

  showSiteDropdown() {
    this.show(this.siteSelectTarget)

    const element = this.siteSelectTarget.children[0]
    const clientId = this.clientSelectInputTarget.value
    const dataSourceUrl = `${this.pathValue}/${clientId}/shifts/sites`

    element.setAttribute("data-scout--lazyload-data-source-url-value", dataSourceUrl)
  }

  hideSiteDropdown() {
    this.hide(this.siteSelectTarget)
  }

  clearSelectedSites() {
    this.siteSelectInputTarget.value = ""

    const element = this.siteSelectTarget.children[0]
    element.setAttribute("data-scout--lazyload-selected-options", "[]")

    const chipElements = [...element.getElementsByClassName("chip-compact mr-2")]
    chipElements.forEach(chip => chip.remove())
  }

  handleClientChange() {
    this.clearSelectedSites()

    this.selectedClient() ? this.showSiteDropdown() : this.hideSiteDropdown()
  }
}
