import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'container', 'openIndicator', 'overlay' ];

  initialize() {
    this.isOpen = false;
    this.containerOpenClass = 'is-open';
    this.openIndicatorClass = 'text-brand-secondary-dark';
    this.closeIndicatorClass = 'text-icon';
  }

  connect() {
    const isMobile = this.element.dataset.isMobile === 'true'
    const isTablet = this.element.dataset.isTablet === 'true'
    const isDesktop = !isMobile && !isTablet;

    this.showOverlay();

    if (isDesktop) {
      this.open();
    } else {
      this.close();
    }
  }

  disconnect() {
    this.close()
  }

  open() {
    this.containerTarget.classList.add(this.containerOpenClass);

    this.openIndicatorTarget.classList.add(this.openIndicatorClass);
    this.openIndicatorTarget.classList.remove(this.closeIndicatorClass);

    this.showOverlay();

    this.isOpen = true;
  }

  close() {
    this.containerTarget.classList.remove(this.containerOpenClass);

    this.openIndicatorTarget.classList.remove(this.openIndicatorClass);
    this.openIndicatorTarget.classList.add(this.closeIndicatorClass);

    this.hideOverlay();

    this.isOpen = false;
  }

  showOverlay() {
    if (!this.hasOverlayTarget) return;

    this.overlayTarget.classList.remove('hidden');
  }

  hideOverlay() {
    if (!this.hasOverlayTarget) return;

    this.overlayTarget.classList.add('hidden');
  }

  toggle() {
    this.isOpen ? this.close() : this.open();
  }
}
