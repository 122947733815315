import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  static values = { handleSelector: String }
  static targets = ['item', 'filterInput', 'searchResultsList', 'selectedList', 'searchResultsListEmptyState', 'header', 'selectedHeader']
  handleInputChangeWithDebounce = debounce(this.handleInputChange, 200)

  debouncedHandleInputChange(event) {
    this.handleInputChangeWithDebounce(event)
  }

  connect() {
    this.hiddenClass = 'hidden'
    this.submitButton = document.getElementById('items-submit-button')
    this.filterInputTarget.focus()
  }

  searchResultElements() {
    return this.searchResultsListTarget.querySelectorAll("div[id^='item-']")
  }

  selectedItemElements() {
    return this.selectedListTarget.querySelectorAll("div[id^='item-']")
  }

  handleInputChange(event) {
    this.searchQuery = event.target.value.toLowerCase()
    this.filterItems()
    this.toggleSelectedItemsListHidden()
    this.toggleSearchResultsListHidden()
  }

  handleCheckboxItemToggle(event) {
    if (!!event.target.closest('input[type="checkbox"]')) {
      event.target.checked = !event.target.checked
    }
    const targetCheckboxElement = event.currentTarget
    const targetCheckbox = targetCheckboxElement.querySelector('input')

    targetCheckbox.checked = !targetCheckbox.checked
    targetCheckbox.checked ? this.handleSelectItem(targetCheckboxElement) : this.handleDeselectItem(targetCheckboxElement)

    this.toggleSelectedItemsListHidden()
    this.toggleSearchResultsListHidden()
  }

  handleSelectItem(checkboxElement) {
    this.selectedListTarget.appendChild(checkboxElement)
  }

  handleDeselectItem(checkboxElement) {
    checkboxElement.remove()
    this.addItemCheckboxElementToSearchResultsList(checkboxElement)
  }

  addItemCheckboxElementToSearchResultsList(checkboxElement) {
    const unsortedCheckboxElements = [...Array.from(this.searchResultElements()), checkboxElement]
    const sortedCheckboxElements = this.sortCheckboxElementsByName(unsortedCheckboxElements)

    this.searchResultsListTarget.innerHTML = ''
    sortedCheckboxElements.forEach(el => this.searchResultsListTarget.appendChild(el))
  }

  sortCheckboxElementsByName(checkboxElements) {
    return checkboxElements.sort((a, b) => a.dataset.name.toLowerCase().localeCompare(b.dataset.name.toLowerCase()))
  }

  filterItems() {
    this.itemTargets.forEach((itemElement) => {
      const name = itemElement.dataset.name.toLowerCase()
      if (name.startsWith(this.searchQuery)) { return this.show(itemElement) }
      if (name.split(' ').some(word => word.startsWith(this.searchQuery))) { return this.show(itemElement) }

      this.hide(itemElement)
    })
  }

  toggleSelectedItemsListHidden() {
    if (this.selectedItemsListShouldBeHidden()) {
      this.hide(this.selectedListTarget)
      this.hide(this.selectedHeaderTarget)
    } else {
      this.show(this.selectedListTarget)
      this.show(this.selectedHeaderTarget)
    }
  }

  selectedItemsListShouldBeHidden() {
    const selectedItemsList = Array.from(this.selectedItemElements())
    return selectedItemsList.length == 0 || selectedItemsList.every(el => el.classList.contains(this.hiddenClass))
  }

  toggleSearchResultsListHidden() {
    if (this.searchResultsListShouldBeHidden()) {
      this.hide(this.searchResultsListTarget)
      if (this.searchQuery) {
        this.showSearchResultsListEmptyState()
      } else {
        this.hide(this.searchResultsListEmptyStateTarget)
        this.hide(this.headerTarget)
      }
    } else {
      this.show(this.searchResultsListTarget)
      this.show(this.headerTarget)
      this.hide(this.searchResultsListEmptyStateTarget)
    }
  }

  showSearchResultsListEmptyState() {
    this.updateSearchResultsListEmptyState()
    this.show(this.searchResultsListEmptyStateTarget)
    this.show(this.headerTarget)
  }

  searchResultsListShouldBeHidden() {
    const searchResultElements = Array.from(this.searchResultElements())
    return searchResultElements.length == 0 || searchResultElements.every(el => el.classList.contains(this.hiddenClass))
  }

  updateSearchResultsListEmptyState() {
    const messageTarget = this.searchResultsListEmptyStateTarget.querySelector('#search-and-select-no-results-message')
    messageTarget.innerText = `No results found for '${this.searchQuery}'`
  }

  show(element) {
    element.classList.remove(this.hiddenClass)
  }

  hide(element) {
    element.classList.add(this.hiddenClass)
  }
}
