import moment from 'moment'

const PLUS_ICON_ELEMENT_ATTRIBUTES = {
  fill: '#000000',
  height: '24',
  width: '24',
  viewBox: '0 0 24 24',
  class: 'fill-current text-icon w-2 h-2 -my-0.5',
}

const LAYER_ELEMENT_ATTRIBUTES = {
  d: 'M0 0h24v24H0V0z',
  fill: 'none'
}

const SVG_SOURCE = "http://www.w3.org/2000/svg"

export const getCurrentMonthAndYear = (startDateOfWeek) => {
  const startDate = moment(startDateOfWeek)
  const month = startDate.format("MMMM")
  const year = startDate.format("YYYY")

  return {
    month: month,
    year: year
  }
}

export const createPlusIconSVG = () => {
  const plusIconElement = document.createElementNS(SVG_SOURCE, 'svg');
  const svgContainerElement = document.createElementNS(SVG_SOURCE, 'g');
  const layerElement = document.createElementNS(SVG_SOURCE, 'path');
  const shapeElement = document.createElementNS(SVG_SOURCE, 'path');

  setAttributes(plusIconElement, PLUS_ICON_ELEMENT_ATTRIBUTES)
  setAttributes(layerElement, LAYER_ELEMENT_ATTRIBUTES)
  shapeElement.setAttribute('d', 'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z')

  svgContainerElement.appendChild(layerElement)
  svgContainerElement.appendChild(shapeElement)
  plusIconElement.appendChild(svgContainerElement)

  return plusIconElement
}

const setAttributes = (element, attributes) => {
  Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
}

export const isCurrentDate = (date, isCurrentWeek) => {
  const today = new Date()
  return isCurrentWeek && date === today.getDate()
}

export const isCurrentDay = (day, isCurrentWeek) => {
  return isCurrentWeek && day == moment().format('ddd')
}
