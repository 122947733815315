import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    requiresBridge: Boolean,
    shiftAttendanceId: String,
    shiftAttendanceObjectType: String
  }

  connect() {
    this.geotagShiftAttendance();
  }

  geotagShiftAttendance() {
    if (this.requiresBridgeValue && this.hasShiftAttendanceIdValue) {
      Android.geotagObject(this.shiftAttendanceObjectTypeValue, this.shiftAttendanceIdValue);
    }
  }
}
