import { Controller } from "stimulus"

export default class extends Controller {
  turbolinks_android() {
    Android.logout()
  }

  turbolinks_ios() {
    webkit.messageHandlers.candidateIOS.postMessage("logout")
  }

  other() {}
}
