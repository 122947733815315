import { Controller } from "stimulus"
import { useEditShift } from "../../../mixins/use_edit_shift"

export default class extends Controller {
  static targets = [
    'finishDate',
    'isOvernight',
    'startDateInput',
    'finishDateInput',
    'startTimeInput',
    'finishTimeInput',
    'clientSelectInput',
    'siteSelect',
    'siteSelectInput'
  ]
  static values = {
    path: String
  };
  DATE_FORMAT = 'YYYY-MM-DD'
  TIME_FORMAT = 'HH-mm'

  connect() {
    useEditShift(this)
    if (this.isShiftOverNight()) this.showFinishDate()

    if (this.canShowSiteDropdown()) this.showSiteDropdown()

    this.focusOnError();
  }

  canShowSiteDropdown() {
    return !!this.clientSelectInputTarget.value
  }

  showSiteDropdown() {
    this.show(this.siteSelectTarget)

    const element = this.siteSelectTarget.children[0]
    const clientId = this.clientSelectInputTarget.value
    const dataSourceUrl = `${this.pathValue}/${clientId}/shifts/sites`

    element.setAttribute("data-scout--lazyload-data-source-url-value", dataSourceUrl)
  }

  hideSiteDropdown() {
    this.hide(this.siteSelectTarget)
  }

  clearSelectedSites() {
    this.siteSelectInputTarget.value = ""

    const element = this.siteSelectTarget.children[0]
    element.setAttribute("data-scout--lazyload-selected-options", "[]")

    const chipElements = [...element.getElementsByClassName("chip-compact mr-2")]
    chipElements.forEach(chip => chip.remove())
  }

  handleClientChange() {
    this.clearSelectedSites()

    this.canShowSiteDropdown() ? this.showSiteDropdown() : this.hideSiteDropdown()
  }
}
