import { Controller }  from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'header', 'confirmModal']

  populateContent(event) {
    event.stopPropagation();

    const templatePath = event.currentTarget.dataset.templatePath
    Rails.ajax({
      url: templatePath,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.containerTarget.innerHTML = data.template;
        this.headerTarget.innerHTML = data.header;
        this.confirmModalTarget.innerHTML = data.confirm_modal_content
      }
    })
  }
}

