import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['background', 'navigation']

  open() {
    this.backgroundTarget.classList.remove('main-nav-bg-fadeOut');
    this.backgroundTarget.classList.add('main-nav-bg-fadeIn');

    this.navigationTarget.classList.remove('main-nav-slideOut');
    this.navigationTarget.classList.add('main-nav-slideIn');
  }

  close() {
    this.backgroundTarget.classList.remove('main-nav-bg-fadeIn');
    this.backgroundTarget.classList.add('main-nav-bg-fadeOut');

    this.navigationTarget.classList.remove('main-nav-slideIn');
    this.navigationTarget.classList.add('main-nav-slideOut');
  }
}
