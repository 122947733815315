import {Controller} from 'stimulus'

export default class extends Controller {
  static values = { completed: Boolean }
  static classes = ['animationCompletion', 'completed']
  static targets = ['task', 'section']

  initialize() {
    this.connected = false
  }
  connect() {
    this.connected = true
  }

  disconnect() {
    this.connected = false
  }

  completedValueChanged(){
    if (!this.connected) return

    this.completedValue ? this.check() : this.uncheck()
  }

  propagateToTasks(){
    this.taskTargets.forEach((task) => {
      task.setAttribute('data-seeker--site-checklist-task-completed-value', this.completedValue)
    })
  }

  toggle(event) {
    this.completedValue = event.detail[0].toggle_type === 'checked'
  }

  toggleFromTasks(){
    const isCompletedTask = (task) => task.getAttribute('data-seeker--site-checklist-task-completed-value') === 'true'
    setTimeout(()=> {
      this.completedValue = this.taskTargets.every(isCompletedTask)
    }, 100)
  }

  check() {
    this.sectionTarget.classList.add(this.animationCompletionClass)
    setTimeout(()=> {
      this.sectionTarget.classList.add(this.completedClass)
      this.sectionTarget.classList.remove(this.animationCompletionClass)
    }, 700)
  }

  uncheck() {
    this.sectionTarget.classList.remove(this.completedClass)
  }
}
