import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    openUrl: String,
    allowBackgroundClose: Boolean,
    discardChangeAttributes: Object
  }

  static targets = ['container']

  connect() {
    let modalContainer = document.getElementById('root-modal-container');
    if (modalContainer.innerHTML !== '')
      document.body.classList.add('fixed');
  }

  open(event) {
    event.preventDefault()
    let modalContainer = document.getElementById('root-modal-container');

    Rails.ajax({
      url: this.openUrlValue,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        modalContainer.innerHTML = data;
        document.body.classList.add('fixed');
      }
    });
  }

  close() {
    let modalContainer = document.getElementById('root-modal-container');
    modalContainer.innerHTML = ""
    document.body.classList.remove('fixed');
  }

  /**
    For example:
    discardChangeAttributesValue = {
        identifier: 'seeker-edit-shift',
        controller: 'seeker--detect-changes',
        method: 'openConfirmationDialogWithCheck'
      }
  */

  handleDiscardChanges(e) {
    const { identifier, controller, method } = this.discardChangeAttributesValue;
    const modalDialog = document.getElementById(identifier);

    if (modalDialog) {
      this.application.getControllerForElementAndIdentifier(modalDialog, controller).execute(method, e)
    }
  }

  clickBackground(e) {
    if (this.allowBackgroundCloseValue && e.target.classList.contains('modal-container')) {
      if (Object.keys(this.discardChangeAttributesValue).length) {
        this.handleDiscardChanges(e);
      } else {
        this.close();
      }
    }
  }
}
