import { Controller } from "stimulus"
export default class extends Controller {

  static values = {
    changed: Boolean
  }

  connect() {
    this.changedValue = true;
  }

  leavingPage(event) {
    if (this.changedValue) {
      if (event.type == "turbolinks:before-visit") {
        // Handle turbolinks navigation
        if (!window.confirm("Are you sure you want to leave this page? Your changes will be lost")) {
          event.preventDefault()
        }
      } else {
        // Handle browser navigation (back, new url)
        event.returnValue = true;
      }
    }
  }

  setUnchanged() {
    this.changedValue = false
  }
}
