import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    url: String,
    type: String
  }

  submitOnEnter(event) {
    if(event.key === 'Enter') {
      this.submit();
    }
  }

  submit() {
    const types = {
      post: 'POST',
    };
    Rails.ajax({
      url: this.urlValue,
      type: types[this.typeValue],
    });
  }
}
