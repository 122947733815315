import { Controller } from "stimulus"
import { debounce, isEmpty } from 'lodash'

export default class extends Controller {
  static values = {
    isSingleChoice: Boolean,
    dataSourceUrl: String,
    isHideChip: Boolean,
    truncate: Number,
    params: Object
  }
  static targets = ['list', 'chip', 'hiddenField', 'textField', 'select']

  connect() {
    this.rehydrateStatesAfterRerender();
    this.displayList = debounce(this.displayList, 200)
    this.listDislaying = false;
  }

  getListDislaying() {
    return this.listDislaying;
  }

  setListDislaying(value) {
    this.listDislaying = value;
  }

  get selectedOptions() {
    if (this.data.has("selectedOptions")) {
      return JSON.parse(this.data.get("selectedOptions"))
    } else {
      return []
    }
  }

  set selectedOptions(options) {
    this.data.set("selectedOptions", JSON.stringify(options))

    this.setHiddenField(options)
    this.setChipList(options)
  }

  add(e) {
    const id = e.currentTarget.dataset.id
    const name = e.currentTarget.dataset.name
    const optionToAdd = { id: id, name: name }
    this.trackChanges = e.currentTarget.dataset.trackChanges;

    if (this.isSingleChoiceValue) {
      this.selectedOptions = [];
    }

    this.selectedOptions = [...this.selectedOptions, optionToAdd]

    this.clearList()

    this.textFieldTarget.value = ''
    this.textFieldTarget.focus()
  }

  clearList() {
    this.listTarget.classList.add('hidden');
    this.listTarget.innerHTML = '';
    this.setListDislaying(false);
  }

  createChip(id, name, highlighted) {
    const isHideChip = this.isHideChipValue;
    const highlightedClasses = highlighted ? ['bg-brand-secondary-200', 'border', 'border-brand-secondary-900'] : [];
    const chipClasses = !isHideChip ? ['chip-compact', 'mr-2', ...highlightedClasses] : ['mr-2'];


    let removeIcon = document.createElement('i')
    removeIcon.setAttribute('data-id', id)
    removeIcon.setAttribute('data-controller', 'seeker--detect-changes')
    removeIcon.setAttribute('data-action', 'click->seeker--lazyload#remove click->seeker--detect-changes#formIsChanged')
    removeIcon.classList.add('fa', 'fa-times', 'cursor-pointer', 'ml-2')

    let span = document.createElement('span')
    span.setAttribute('data-seeker--lazyload-target', 'chip')
    span.classList.add('chip-el', ...chipClasses)
    span.innerHTML = this.truncateValue ? this.truncateString(name, this.truncateValue) : name
    if(!isHideChip) span.appendChild(removeIcon)
    return span
  }

  createNoResults(name) {
    let optionDocument = this.listTarget.querySelector("#infiniteScrollOptions")

    if (optionDocument.innerHTML.trim() !== '' || name === '') return;

    let div = document.createElement('div')
    div.classList.add('px-4', 'py-2')
    div.innerHTML = `No results for ${name}`
    this.listTarget.innerHTML = div.outerHTML
  }

  displayList() {
    const filter_name = this.textFieldTarget.value || ''
    const selected = this.selectedOptions.map(option => option.id)

    let params = {
      name: filter_name,
      exclude_ids: selected,
      ...this.paramsValue
    }

    this.fetchData(params)
  }

  fetchData(params) {
    let searchParams = new URLSearchParams()

    for (const key in params) {
      searchParams.append(`filter[${key}]`, params[key]);
    }

    Rails.ajax({
      url: `${this.dataSourceUrlValue}?${searchParams}`,
      type: 'GET',
      success: (data) => {
        this.clearList();
        this.setListDislaying(true);
        this.listTarget.innerHTML = data.documentElement.innerHTML
        this.listTarget.classList.remove('hidden')

        this.createNoResults(params['name'])
      }
    });
  }

  remove(e) {
    const selectedOptions = this.selectedOptions
    const optionToRemoveIndex = selectedOptions.findIndex((option) => option.id === e.target.dataset.id)

    if (optionToRemoveIndex !== -1) {
      selectedOptions.splice(optionToRemoveIndex, 1)
      this.selectedOptions = selectedOptions
    }

    this.textFieldTarget.focus();
    this.displayList();
  }

  setChipList(options) {
    const isHideChip = this.isHideChipValue;
    const isSingleChoice = this.isSingleChoiceValue;

    this.chipTargets.forEach(chip => chip.remove())

    options.forEach(option => {
      const chip = this.createChip(option.id, option.name, option.highlighted)
      this.textFieldTarget.insertAdjacentElement('beforebegin', chip)
    })

    if (isHideChip && isSingleChoice) {
      // Add left padding to chip list
      if (this.chipTargets.length) this.chipTargets[0].classList.add('pl-2')

      this.setHiddenTextField()

      this.chipTargets.forEach(chip => {
        chip.addEventListener('click', () => {
          this.setDisplayTextField()
          this.setHiddenChipTargets()
          this.textFieldTarget.focus()
          // Show options list
          this.displayList()

          this.textFieldTarget.onblur = () => {
            this.setDisplayChipTargets()
            this.setHiddenTextField()
          }
        })
      })
    }
  }

  setHiddenField(options) {
    this.hiddenFieldTarget.value = options.map((option) => option.id)
    this.hiddenFieldTarget.dispatchEvent(new Event('change'))
  }

  rehydrateStatesAfterRerender() {
    const selectedOptions = this.selectedOptions;
    if (isEmpty(selectedOptions)) return;

    this.setHiddenField(selectedOptions)
    this.setChipList(selectedOptions);
  }

  toggleDisplayList() {
    const listDislaying = this.getListDislaying();
    if (listDislaying) {
      this.clearList();
    } else {
      this.displayList();
    }
  }

  setHiddenTextField() {
    this.textFieldTarget.classList.add('hidden')
    this.selectTarget.classList.add('p-2.5')
  }

  setDisplayTextField() {
    this.textFieldTarget.classList.remove('hidden')
    this.selectTarget.classList.remove('p-2.5')
  }

  setDisplayChipTargets() {
    this.chipTargets.forEach(chip => chip.classList.remove('hidden'))
  }

  setHiddenChipTargets() {
    this.chipTargets.forEach(chip => chip.classList.add('hidden'))
  }

  truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  // execute function as dynamically
  execute(fname, e) {
    let functionMap = {
      "setDisplayTextField": (e) => {
        this.setDisplayTextField(e)
      }
    };

    return functionMap[fname](e);
  }
}
