import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'finishDateInput',
  ];

  handleCheck(event) {
    !!event.target.checked ? this.showFinishDateInput() : this.hideFinishDateInput()
  }

  showFinishDateInput() {
    this.finishDateInputTarget.classList.remove('hidden');
  }

  hideFinishDateInput() {
    this.finishDateInputTarget.classList.add('hidden');
  }
}
