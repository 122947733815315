import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    const containerTarget = this.containerTarget;
    const newJobImportPath = containerTarget.dataset.newJobImportPath;
    const jobId = containerTarget.dataset.jobId;

    Rails.ajax({
      url: `${newJobImportPath}?job_id=${jobId}`,
      type: 'GET',
      dataType: 'json',
    });
  }
}
