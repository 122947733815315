const show = (element) => {
  element.classList.remove(element.dataset.hiddenClass || 'hidden')
}

const hide = (element) => {
  element.classList.add(element.dataset.hiddenClass || 'hidden')
}

module.exports = {
  show,
  hide
}
