import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.confirmationDialogId = this.data.get("id");
  }

  openConfirmationDialog(event) {
    const confirmationDialog = document.getElementById(this.confirmationDialogId)
    this.application.getControllerForElementAndIdentifier(confirmationDialog, 'modal').open(event)
  }
}
