import { Controller } from "stimulus"
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = ['initialFocusField', 'confirmButton']
  submitWithDebounce = debounce(this.submit, 200);

  blurOnEnter(e) {
    if (e.key === 'Enter') {
      e.target.blur();
      this.submit();
    }
  }

  connect() {
    this.focusInitialElement()
    this.setRemoteGetFormsEventListener()
  }

  shouldSetRemoteGetFormsEventListener() {
    return this.element.dataset.remote == 'true'
      && this.element.method == 'get'
      && this.data.get('enableGetSupport') == 'true'
  }

  setRemoteGetFormsEventListener(){
    if (!this.shouldSetRemoteGetFormsEventListener()) return;

    this.element.addEventListener('ajax:beforeSend', function (event) {
      const detail = event.detail, options = detail[1];
      Turbolinks.visit(options.url, { action: "replace" });
      event.preventDefault();
    });
  }

  debouncedSubmit () {
    this.submitWithDebounce();
  }

  focusInitialElement(){
    if (!this.hasInitialFocusFieldTarget) return

    this.initialFocusFieldTarget.focus()
    if (this.initialFocusFieldTarget.dataset.placeCursorAtEnd == 'true') {
      this.initialFocusFieldTarget.setSelectionRange(-1, -1)
    }
  }

  submit() {
    Rails.fire(this.element, 'submit');
  }

  toggleConfirmButton() {
    this.confirmButtonTarget.toggleAttribute('disabled')
  }
}
