import moment from "moment";

export const useEditShift = controller => {
  Object.assign(controller, {
    adjustFinishDate() {
      let startDate = this.startDateInputTarget.value
      if (this.isShiftOverNight()) {
        this.adjustDate(this.finishDateInputTarget, startDate, 1)
      } else {
        this.adjustDate(this.finishDateInputTarget, startDate)
      }
    },

    adjustFinishTime() {
      let startTime = this.startTimeInputTarget.value
      this.adjustTime(this.finishTimeInputTarget, startTime, 1)
    },

    isShiftOverNight() {
      return !!this.isOvernightTarget.checked
    },

    handleCheck() {
      this.isShiftOverNight() ? this.showFinishDate() : this.hideFinishDate()
    },

    showFinishDate() {
      this.show(this.finishDateTarget)
      this.adjustFinishDateFromStartDate()
    },

    hideFinishDate() {
      this.hide(this.finishDateTarget)
      this.adjustFinishDateFromStartDate()
    },

    adjustFinishDateFromStartDate() {

      let startDate = this.startDateInputTarget.value
      let amount = this.isShiftOverNight() ? 1 : 0
      this.adjustDate(this.finishDateInputTarget, startDate, amount)
    },

    adjustDate(element, date, amount = 0) {
      if (!date || !element?._flatpickr) return;

      element._flatpickr.setDate(moment(date).add(amount, 'day').format(this.DATE_FORMAT))
    },

    adjustTime(element, time, amount = 0) {
      if (!time) return;
      element._flatpickr.setDate(moment(time, 'hh:mm').add(amount, 'hour').format(this.TIME_FORMAT))
    },

    focusOnError() {
      let errorElement = this.element.querySelector('p.error[error-for]')
      if (!errorElement) return;

      let errorFieldNames = errorElement.getAttribute('error-for').split(' ')

      for (const name of errorFieldNames) {
        let ele = this.element.querySelector(`input[name='${name}']:not([type=hidden]):not(.hidden)`)
        if (ele) ele.focus();
      }
    },

    show(element) {
      element.classList.remove('hidden')
    },

    hide(element) {
      element.classList.add('hidden')
    }
  })
}
