import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  static values = { handleSelector: String }
  static targets = [
    'worker',
    'filterInput',
    'workerSearchResultsList',
    'selectedWorkersList',
    'workerSearchResultsListEmptyState',
    'workersHeader',
    'selectedHeader'
  ]
  handleInputChangeWithDebounce = debounce(this.handleInputChange, 200)

  debouncedHandleInputChange(event) {
    this.handleInputChangeWithDebounce(event)
  }

  connect() {
    this.hiddenClass = 'hidden'
    this.submitButton = document.getElementById('workers-submit-button')
    this.filterInputTarget.focus()
  }

  workerResultElements() {
    return this.workerSearchResultsListTarget.querySelectorAll("div[id^='worker-checkbox-']")
  }

  selectedWorkerElements() {
    return this.selectedWorkersListTarget.querySelectorAll("div[id^='worker-checkbox-']")
  }

  handleInputChange(event) {
    this.searchQuery = event.target.value.toLowerCase()
    this.filterWorkers()
    this.toggleSelectedWorkersListHidden()
    this.toggleWorkerSearchResultsListHidden()
  }

  handleCheckboxToggle(event) {
    const targetCheckboxElement = document.getElementById(`worker-checkbox-${event.target.value}`)
    event.target.checked ? this.handleSelectWorker(targetCheckboxElement) : this.handleDeselectWorker(targetCheckboxElement)

    this.toggleSelectedWorkersListHidden()
    this.toggleWorkerSearchResultsListHidden()
    this.toggleSubmitButtonDisabled()
  }

  handleSelectWorker(newSelectedWorker) {
    this.selectedWorkersListTarget.appendChild(newSelectedWorker)
  }

  handleDeselectWorker(checkboxElement) {
    checkboxElement.remove()
    this.addWorkerCheckboxElementToWorkerSearchResultsList(checkboxElement)
  }

  addWorkerCheckboxElementToWorkerSearchResultsList(checkboxElement) {
    const unsortedCheckboxElements = [...Array.from(this.workerResultElements()), checkboxElement]
    const sortedCheckboxElements = this.sortCheckboxElementsByName(unsortedCheckboxElements)

    this.workerSearchResultsListTarget.innerHTML = ''
    sortedCheckboxElements.forEach(el => this.workerSearchResultsListTarget.appendChild(el))
  }

  sortCheckboxElementsByName(checkboxElements) {
    return checkboxElements.sort((a, b) => a.dataset.name.toLowerCase().localeCompare(b.dataset.name.toLowerCase()))
  }

  filterWorkers() {
    this.workerTargets.forEach((workerElement) => {
      const name = workerElement.dataset.name.toLowerCase()
      if (name.startsWith(this.searchQuery)) { return this.show(workerElement) }
      if (name.split(' ').some(word => word.startsWith(this.searchQuery))) { return this.show(workerElement) }

      this.hide(workerElement)
    })
  }

  toggleSelectedWorkersListHidden() {
    if (this.selectedWorkersListShouldBeHidden()) {
      this.hide(this.selectedWorkersListTarget)
      this.hide(this.selectedHeaderTarget)
    } else {
      this.show(this.selectedWorkersListTarget)
      this.show(this.selectedHeaderTarget)
    }
  }

  selectedWorkersListShouldBeHidden() {
    const selectedWorkersList = Array.from(this.selectedWorkerElements())
    return selectedWorkersList.length == 0 || selectedWorkersList.every(el => el.classList.contains(this.hiddenClass))
  }

  toggleWorkerSearchResultsListHidden() {
    if (this.workerSearchResultsListShouldBeHidden()) {
      this.hide(this.workerSearchResultsListTarget)
      if (this.searchQuery) {
        this.showWorkerSearchResultsListEmptyState()
      } else {
        this.hide(this.workerSearchResultsListEmptyStateTarget)
        this.hide(this.workersHeaderTarget)
      }
    } else {
      this.show(this.workerSearchResultsListTarget)
      this.show(this.workersHeaderTarget)
      this.hide(this.workerSearchResultsListEmptyStateTarget)
    }
  }

  showWorkerSearchResultsListEmptyState() {
    this.updateWorkerSearchResultsListEmptyState()
    this.show(this.workerSearchResultsListEmptyStateTarget)
    this.show(this.workersHeaderTarget)
  }

  workerSearchResultsListShouldBeHidden() {
    const workerResultElements = Array.from(this.workerResultElements())
    return workerResultElements.length == 0 || workerResultElements.every(el => el.classList.contains(this.hiddenClass))
  }

  toggleSubmitButtonDisabled() {
    this.selectedWorkerElements().length > 0 ? this.submitButton.removeAttribute('disabled') : this.submitButton.setAttribute('disabled', true)
  }

  updateWorkerSearchResultsListEmptyState() {
    const messageTarget = this.workerSearchResultsListEmptyStateTarget.querySelector('p')
    messageTarget.innerText = `No results found for '${this.searchQuery}'`
  }

  show(element) {
    element.classList.remove(this.hiddenClass)
  }

  hide(element) {
    element.classList.add(this.hiddenClass)
  }

  closeConfirmOverlappingScheduleDialog() {
    const confirmOverlappingScheduleDialog = document.getElementById('confirm-overlapping-schedule-dialog')
    if(confirmOverlappingScheduleDialog !== null) confirmOverlappingScheduleDialog.remove()
  }
}
