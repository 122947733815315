import imageCompression from 'browser-image-compression';
import EXIF from 'exif-js'

// maxSize unit is kilobyte
// maxDimension unit is pixel

export function compressImage(image, { maxSize = 1000000, maxDimension = 2000 } ) {
  return new Promise((resolve, reject) => {
    const options = {
      maxSizeMB: maxSize / 1000000,
      maxWidthOrHeight: maxDimension,
      useWebWorker: true
    }

    imageCompression(image, options)
      .then(function(compressedFile) {
        resolve(compressedFile);
      })
      .catch(function(error) {
        reject(error);
      });
  })
}

export async function getExifData(image) {
  return new Promise((resolve) => {
    EXIF.getData(image, () => {
      resolve(image.exifdata); // This will always be present, even if it only contains dimension data
    })
  })
}

export function convertDegreeMinuteSecondToDegreeDecimal(gpsAttribute) {
  const [ degrees, minutes, seconds ] = gpsAttribute
  return (degrees + (minutes / 60) + (seconds / 3600))
}
