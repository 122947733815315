import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.confirmationDialogId = this.data.get("id");
  }

  connect() {
    // Should be used along with this:
    // <div class="hidden" name="false" id="changed_flag"></div>
    // to track changes when submitting form, as the form component will be reloaded when submitting
    const changedFlag = document.getElementById('changed_flag');
    if (changedFlag) {
      const value = changedFlag.value;

      if (value == "true") {
        this.setChanged("true");
      }
    }
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbolinks:before-visit") {
        // Handle turbolinks navigation
        this.openConfirmationDialogWithCheck(event);
      } else {
        // Handle browser navigation (back, new url)
        event.returnValue = true;
      }
    }
  }

  goingToPage(event) {
    const confirmationDialog = document.getElementById(this.confirmationDialogId);
    this.application.getControllerForElementAndIdentifier(confirmationDialog, 'modal').close();
    this.resetChangedFlag()
  }

  resetChangedFlag() {
    const changedFlag = document.getElementById('changed_flag');
    if (changedFlag) {
      changedFlag.value = 'false';
    }
  }

  openConfirmationDialogWithCheck(event) {
    if (this.isFormChanged()) {
      const confirmationDialog = document.getElementById(this.confirmationDialogId);
      this.application.getControllerForElementAndIdentifier(confirmationDialog, 'modal').open(event);
    } else {
      const rootModalContainer = document.getElementById('form_modal');
      this.application.getControllerForElementAndIdentifier(rootModalContainer, 'seeker--form-modal').close();
    }
  }

  openConfirmationDialogWithCheckMobile(event) {
    if (this.isFormChanged()) {
      const confirmationDialog = document.getElementById(this.confirmationDialogId);
      this.application.getControllerForElementAndIdentifier(confirmationDialog, 'modal').open(event);
    } else {
      history.back();
    }
  }

  formIsChanged() {
    this.setChanged("true");
  }

  setChanged(changed) {
    const changedFlag = document.getElementById('changed_flag');

    if (changedFlag) {
      changedFlag.value = changed;
    }
  }

  isFormChanged() {
    const changedFlag = document.getElementById('changed_flag');
    if (changedFlag) {
      return changedFlag.value == "true";
    }
  }

  allowFormSubmission() {
    this.setChanged('false');
  }


  // execute function as dynamically
  execute(fname, e) {
    let functionMap = {
      "openConfirmationDialogWithCheck": (e) => {
        this.openConfirmationDialogWithCheck(e)
      }
    };

    return functionMap[fname](e);
  }
}
