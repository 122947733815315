import { Controller } from 'stimulus'
import moment from 'moment'

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD"
const NAVIGATION_STEPS = { back: -1, forward: 1 }

export default class CalendarNavigationController extends Controller {

  initialize() {
    const fromDate = this.fromDateTarget.value
    this.startDateOfWeekValue = moment(fromDate).startOf("isoWeek").format(DEFAULT_DATE_FORMAT)
    this.setDatesInWeekValue()
    this.SetDatesInWeek()
    this.setMonthAndYearCellWidth()
  }

  setMonthAndYearCellWidth() {
    const monthAndYearCell = document.getElementById('year-and-month-cell')
    const firstWorkerCell = document.getElementsByClassName('standard-cell')[0]

    if(!monthAndYearCell || !firstWorkerCell) { return }

    monthAndYearCell.style.minWidth = firstWorkerCell.offsetWidth + "px"
  }

  setDatesInWeekValue() {
    let startDate = this.startDateOfWeekValue
    const lastDateOfWeek = moment(this.startDateOfWeekValue).endOf("isoWeek").toDate()
    let dates = []
    while (moment(startDate).isBefore(lastDateOfWeek)) {
      dates.push(startDate)
      startDate = moment(startDate, DEFAULT_DATE_FORMAT).add("1", "day").toDate()
    }
    this.datesInWeekValue = dates
  }

  SetDatesInWeek() {
    const currentWeekStart = moment().startOf("isoWeek").format(DEFAULT_DATE_FORMAT)
    this.isCurrentWeek = currentWeekStart == this.startDateOfWeekValue
  }

  goBack() {
    this.navigateTheCalendar(NAVIGATION_STEPS.back)
  }

  goForward() {
    this.navigateTheCalendar(NAVIGATION_STEPS.forward)
  }

  navigateTheCalendar(directionValue){
    this.fromDateTarget.value = moment(this.startDateOfWeekValue)
                                  .add(directionValue, "week")
                                  .startOf("isoWeek")
                                  .format(DEFAULT_DATE_FORMAT)
  }

  backToToday() {
    this.fromDateTarget.value = moment().startOf("isoWeek").format(DEFAULT_DATE_FORMAT)
  }
}
