import { Controller } from 'stimulus'

/*
  The purpose of this controller is to handle modal for bottom menu, which has position fixed
  The reason was modal won't work with container (parent node) whose position is fixed or relative
*/
export default class extends Controller {
  static targets = ['finishButton', 'form']

  initialize() {
    this.timer = null
  }

  connect() {
    const modalContainer = document.createElement("div");
    modalContainer.id = "modalContainer"
    modalContainer.setAttribute('data-controller', 'modal')
    document.getElementsByTagName("body")[0].appendChild(modalContainer)
  }

  disconnect() {
    clearTimeout(this.timer)
  }

  openFinishShiftConfirmationDialog(event) {
    const confirmationDialog = document.getElementById("confirmation-modal")
    const parentNode = document.getElementById("modalContainer")
    parentNode.appendChild(confirmationDialog)

    this.application.getControllerForElementAndIdentifier(parentNode, 'modal').open(event)
  }

  beforeCompleteSiteAttendance(event) {
    event.preventDefault();

    document.getElementById("complete-site-icon").classList.add('hidden')
    document.getElementsByClassName("animated-checkmark")[0].classList.remove('hidden')
    this.timer = setTimeout(() => {
      this.formTarget.submit()
    }, 1000);
  }
}
