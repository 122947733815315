import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'map' ];
  static values = {
    candidate: Object,
    site: Object,
    siteMarker: String,
    candidateMarker: String
  };

  connect() {
    if (typeof (google) != "undefined"){
      this.drawMap();
    }
  }

  drawMap() {
    const isCandidateCoordindatesNotEmpty = Object.keys(this.candidateValue).length > 0
    const centerCoordinates = isCandidateCoordindatesNotEmpty ? this.candidateValue : this.siteValue
    const map = new google.maps.Map(this.mapTarget, {
      zoom: 14,
      center: this.getCoordinates(centerCoordinates),
    });
    if(isCandidateCoordindatesNotEmpty) {
      this.createMarker(this.candidateValue, this.candidateMarkerValue, map);
    }
    this.createMarker(this.siteValue, this.siteMarkerValue, map);
  }

  createMarker(data, svg, map) {
    new google.maps.Marker({
      position: this.getCoordinates(data),
      map,
      icon: { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg), scaledSize: new google.maps.Size(40, 40) },
      optimized: false,
    });
  };

  decode = (string) => string.replace(/\+/g,' ');

  getCoordinates(data) {
    return { lat: data.lat, lng: data.lon }
  };
}
