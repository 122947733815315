import { Controller } from 'stimulus';
import SignaturePad from 'signature_pad';

export default class extends Controller {
  static targets = ["canvas", "hiddenField"];

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget);
    window.onresize = this.resizeCanvas.bind(this);
    this.resizeCanvas();
  }

  resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio = Math.max(window.devicePixelRatio || 1, 1);

    // This part causes the canvas to be cleared
    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio;
    this.canvasTarget.getContext("2d").scale(ratio, ratio);

    // This library does not listen for canvas changes, so after the canvas is automatically
    // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
    // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
    // that the state of this library is consistent with visual state of the canvas, you
    // have to clear it manually.
    this.signaturePad.clear();
  }

  clear(event) {
    event.preventDefault();

    this.signaturePad.clear();
  }

  svgDataURL() {
    return this.signaturePad.toDataURL('image/svg+xml');
  }

  updateHiddenFieldValue(event) {
    event.preventDefault();

    this.hiddenFieldTarget.value = this.signaturePad.isEmpty() ? '' : this.svgDataURL();
    this.triggerHiddenFieldChangeEvent(this.hiddenFieldTarget);
  }

  triggerHiddenFieldChangeEvent(hiddenField) {
    const customEvent = new Event('change');
    hiddenField.dispatchEvent(customEvent);
  }

  handleSkipClick(e) {
    const skipSignatureField = document.getElementById('skip-signature-field')
    const isSkippedSignature = !JSON.parse(skipSignatureField.value) //Convert string to boolean
    skipSignatureField.value = isSkippedSignature

    let skipButtonText = isSkippedSignature ? "Skipped" : "Skip"
    e.currentTarget.innerText = skipButtonText
  }
}
