import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.unactiveClasses = ['hover:bg-ui-25', 'bg-surface']
    this.activeClasses = ['active-target', 'bg-brand-tertiary-50', 'hover:bg-brand-tertiary-100']
  }

  toggleActiveClass(e) {
    if(e.currentTarget.classList.contains("active-target")) {
      this.unaddActiveClassesTo(e)
    }
    else {
      this.addActiveClassesTo(e)
    }
  }

  addActiveClassesTo(e) {
    const previousActiveTarget = document.getElementsByClassName('active-target')[0]
    if(previousActiveTarget) {
      previousActiveTarget.classList.remove(...this.activeClasses)
    }

    e.currentTarget.classList.remove(...this.unactiveClasses)
    e.currentTarget.classList.add(...this.activeClasses)
  }

  unaddActiveClassesTo(e) {
    e.currentTarget.classList.add(...this.unactiveClasses)
    e.currentTarget.classList.remove(...this.activeClasses)
  }
}
