import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    requiresBridge: Boolean,
    addressAttendanceId: String,
    addressAttendanceObjectType: String
  }

  connect() {
    this.geotagAddressAttendance();
  }

  geotagAddressAttendance() {
    if (this.requiresBridgeValue && this.hasAddressAttendanceIdValue) {
      Android.geotagObject(this.addressAttendanceObjectTypeValue, this.addressAttendanceIdValue);
    }
  }
}
