import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'map' ];
  static values = {
    job: Object,
    event: Object
  };
  jobMarkerSvg =
`<?xml version="1.0"?>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 15.3333C14.16 15.3333 12.6667 13.84 12.6667 12C12.6667 10.16 14.16 8.66667 16 8.66667C17.84 8.66667 19.3333 10.16 19.3333 12C19.3333 13.84 17.84 15.3333 16 15.3333Z" fill="white"/>
<path d="M16 2.66667C10.84 2.66667 6.66666 6.84 6.66666 12C6.66666 19 16 29.3333 16 29.3333C16 29.3333 25.3333 19 25.3333 12C25.3333 6.84 21.16 2.66667 16 2.66667ZM16 15.3333C14.16 15.3333 12.6667 13.84 12.6667 12C12.6667 10.16 14.16 8.66667 16 8.66667C17.84 8.66667 19.3333 10.16 19.3333 12C19.3333 13.84 17.84 15.3333 16 15.3333Z" fill="#31485A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2579 30.0036L16 29.3333L16.7421 30.0036L16 30.8252L15.2579 30.0036ZM16.6607 28.5717C18.7898 26.0593 25.3333 17.8612 25.3333 12C25.3333 6.84 21.16 2.66667 16 2.66667C10.84 2.66667 6.66666 6.84 6.66666 12C6.66666 17.8612 13.2102 26.0593 15.3393 28.5717C15.753 29.0598 16 29.3333 16 29.3333C15.2579 30.0036 15.2579 30.0036 15.2579 30.0036L15.257 30.0027L15.2553 30.0008L15.2493 29.9941L15.2275 29.9697C15.2086 29.9486 15.1812 29.9177 15.1457 29.8776C15.0748 29.7973 14.9718 29.6798 14.8413 29.5284C14.5805 29.2256 14.2096 28.7871 13.7652 28.2395C12.8773 27.1455 11.6918 25.611 10.5044 23.851C9.31882 22.0938 8.11893 20.0938 7.21239 18.0703C6.31173 16.0599 5.66666 13.9523 5.66666 12C5.66666 6.28772 10.2877 1.66667 16 1.66667C21.7123 1.66667 26.3333 6.28772 26.3333 12C26.3333 13.9523 25.6883 16.0599 24.7876 18.0703C23.8811 20.0938 22.6812 22.0938 21.4956 23.851C20.3082 25.611 19.1227 27.1455 18.2348 28.2395C17.7904 28.7871 17.4195 29.2256 17.1586 29.5284C17.0282 29.6798 16.9252 29.7973 16.8543 29.8776C16.8188 29.9177 16.7914 29.9486 16.7725 29.9697L16.7507 29.9941L16.7447 30.0008L16.743 30.0027C16.743 30.0027 16.7421 30.0036 16 29.3333C16 29.3333 16.247 29.0598 16.6607 28.5717Z" fill="white"/>
</svg>`;
  eventMarkerSvg =
`<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 5.33334C17.4667 5.33334 18.6667 6.53334 18.6667 8C18.6667 9.48 17.4667 10.6667 16 10.6667C14.5334 10.6667 13.3334 9.48 13.3334 8C13.3334 6.53334 14.5334 5.33334 16 5.33334ZM16 18.6667C13.7734 18.6667 11.8134 17.5333 10.6667 15.8C10.6934 14.04 14.2267 13.0667 16 13.0667C17.7734 13.0667 21.3067 14.04 21.3334 15.8C20.1867 17.5333 18.2267 18.6667 16 18.6667Z" fill="white"/>
<path d="M16 2.66667C10.8534 2.66667 6.66669 6.85334 6.66669 12C6.66669 19 16 29.3333 16 29.3333C16 29.3333 25.3334 19 25.3334 12C25.3334 6.85334 21.1467 2.66667 16 2.66667ZM16 5.33333C17.4667 5.33333 18.6667 6.53333 18.6667 8C18.6667 9.48 17.4667 10.6667 16 10.6667C14.5334 10.6667 13.3334 9.48 13.3334 8C13.3334 6.53333 14.5334 5.33333 16 5.33333ZM16 18.6667C13.7734 18.6667 11.8134 17.5333 10.6667 15.8C10.6934 14.04 14.2267 13.0667 16 13.0667C17.7734 13.0667 21.3067 14.04 21.3334 15.8C20.1867 17.5333 18.2267 18.6667 16 18.6667Z" fill="#C14C14"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2579 30.0036L16 29.3333L16.7421 30.0036L16 30.8252L15.2579 30.0036ZM16.6607 28.5717C18.7898 26.0593 25.3334 17.8612 25.3334 12C25.3334 6.85333 21.1467 2.66667 16 2.66667C10.8534 2.66667 6.66669 6.85333 6.66669 12C6.66669 17.8612 13.2102 26.0593 15.3393 28.5717C15.753 29.0598 16 29.3333 16 29.3333C15.2579 30.0036 15.2579 30.0036 15.2579 30.0036L15.257 30.0027L15.2553 30.0008L15.2494 29.9941L15.2275 29.9697C15.2086 29.9486 15.1812 29.9177 15.1457 29.8776C15.0748 29.7973 14.9718 29.6798 14.8414 29.5284C14.5806 29.2256 14.2096 28.7871 13.7652 28.2395C12.8773 27.1455 11.6919 25.611 10.5044 23.851C9.31884 22.0938 8.11896 20.0938 7.21242 18.0703C6.31175 16.0599 5.66669 13.9523 5.66669 12C5.66669 6.30105 10.3011 1.66667 16 1.66667C21.699 1.66667 26.3334 6.30105 26.3334 12C26.3334 13.9523 25.6883 16.0599 24.7876 18.0703C23.8811 20.0938 22.6812 22.0938 21.4957 23.851C20.3082 25.611 19.1227 27.1455 18.2348 28.2395C17.7904 28.7871 17.4195 29.2256 17.1587 29.5284C17.0282 29.6798 16.9253 29.7973 16.8543 29.8776C16.8189 29.9177 16.7914 29.9486 16.7725 29.9697L16.7507 29.9941L16.7447 30.0008L16.743 30.0027C16.743 30.0027 16.7421 30.0036 16 29.3333C16 29.3333 16.247 29.0598 16.6607 28.5717Z" fill="white"/>
</svg>`;

  connect() {
    if (typeof (google) != "undefined"){
      this.drawMap();
    }
  }

  drawMap() {
    const map = new google.maps.Map(this.mapTarget, {
      zoom: 14,
      center: this.getCoordinates(this.eventValue),
    });
    this.createMarker(this.jobValue, this.jobMarkerSvg, map);
    this.createMarker(this.eventValue, this.eventMarkerSvg, map);
  }

  createMarker(data, svg, map) {
    new google.maps.Marker({
      position: this.getCoordinates(data),
      map,
      title: this.decode(data.title),
      icon: { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg), scaledSize: new google.maps.Size(40, 40) },
      optimized: false,
    });
  };

  decode = (string) => string.replace(/\+/g,' ');

  getCoordinates(data) {
    return { lat: data.lat, lng: data.lon }
  };
}
