import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    formId: String,
    requiresInjectCoordinatesWithFormId: Boolean
  }
  turbolinks_android(event) {
    event.preventDefault()

    if(this.requiresInjectCoordinatesWithFormIdValue) {
      Android.injectCoordinatesAndSubmit(this.formIdValue)
    }
    else {
      Android.injectCoordinatesAndSubmit()
    }
  }

  turbolinks_ios(event) {
    event.preventDefault()

    if(this.requiresInjectCoordinatesWithFormIdValue) {
      webkit.messageHandlers.candidateIOS.postMessage(`injectCoordinatesAndSubmit(${this.formIdValue})`)
    }
    else {
      webkit.messageHandlers.candidateIOS.postMessage('injectCoordinatesAndSubmit')
    }
  }

  other() {}
}
