import { Controller } from "stimulus";
import { compressImage, getExifData, convertDegreeMinuteSecondToDegreeDecimal } from 'src/helpers';

export default class extends Controller {
  static targets = ['button', 'fileUpload', 'loading'];

  connect() {
    this.hideLoadingState();
  }

  async handleUpload(event) {
    const attachedImages = event.target.files;
    if ( !attachedImages || attachedImages.length === 0 ) return;

    Array.from(attachedImages).forEach(async (attachedImage) => {
      const filename = attachedImage.name;

      this.fileUploadTarget.disabled = true;
      this.showLoadingState();

      try {
        const { GPSLongitude, GPSLongitudeRef, GPSLatitude, GPSLatitudeRef, DateTime } = await getExifData(attachedImage)
        const compressedImage = await this.compress(attachedImage);
        let metadata = {}

        if (GPSLongitude && GPSLongitudeRef && GPSLatitude && GPSLatitudeRef) {
          const longitude = convertDegreeMinuteSecondToDegreeDecimal(GPSLongitude);
          const latitude = convertDegreeMinuteSecondToDegreeDecimal(GPSLatitude);
          metadata = {
            longitude: GPSLongitudeRef === "W" ? -longitude : longitude,
            latitude: GPSLatitudeRef === "S" ? -latitude : latitude
          };
        };

        if (DateTime) {
          metadata.takenAt = DateTime;
        };

        const newImage = await this.upload(compressedImage, filename, metadata);

        this.insertNewImage(newImage);
        this.reset();
        this.hideLoadingState();
      } catch(error) {
        console.error(error);
        Bugsnag.notify(error);
        this.reset();
        this.hideLoadingState();
      }
    })
  }

  compress(image) {
    const maxSize = Number(this.data.get('maxSize'));
    const maxDimension = Number(this.data.get('maxDimension'));

    const options = {
      maxSize,
      maxDimension,
    }

    return new Promise((resolve, reject) => {
      compressImage(image, options)
        .then(compressedImage => resolve(compressedImage))
        .catch(error => reject(error));
    });
  }

  upload(image, filename, metadata) {
    const url = this.data.get('url');
    const formData = new FormData();

    formData.append('image', image, filename);
    metadata.takenAt && formData.append('takenAt', metadata.takenAt);
    metadata.longitude && formData.append('longitude', metadata.longitude);
    metadata.latitude && formData.append('latitude', metadata.latitude);

    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: url,
        type: "post",
        data: formData,
        dataType: 'json',
        success: (json) => resolve(json.item),
        error: (error) => reject(error),
      });
    });
  }

  insertNewImage(image) {
    this.buttonTarget.insertAdjacentHTML('beforebegin', image);
  }

  reset() {
    this.fileUploadTarget.value = "";
    this.fileUploadTarget.disabled = false;
  }

  showLoadingState() {
    this.loadingTarget.classList.remove('hidden');
  }

  hideLoadingState() {
    this.loadingTarget.classList.add('hidden');
  }
}
