import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['checkbox']

  connect() {
    const status = this.data.get("status")
    if (status === 'checked') {
      this.checkboxTarget.checked = true
    } else if (status === 'indeterminate') {
      this.checkboxTarget.indeterminate = true
    }
  }

  submit(e) {
    const url = this.data.get("url")
    const accountTagId = e.target.value
    const accountCandidateIds = this.data.get("accountCandidateIds").split(',')

    let requestType;
    if (e.target.checked) {
      requestType = 'POST'
    } else {
      requestType = 'DELETE'
    }

    let data = `business_models_scout_account_candidate_tags[account_tag_id]=${accountTagId}` + accountCandidateIds.reduce((accumulator, currentValue) => {
      return accumulator + `&business_models_scout_account_candidate_tags[account_candidate_ids][]=${currentValue}`
    }, '')

    // Send through the candidate being displayed on the profile to refresh if necessary
    const profileElement = document.querySelectorAll("[id^='scout_candidates_profile_component_account_candidate']")[0]
    if (profileElement) {
      const focusedAccountCandidateId = profileElement.id.split("_").pop()
      data = `focused_account_candidate_id=${focusedAccountCandidateId}&` + data
    }

    Rails.ajax({
      url: url,
      type: requestType,
      data: data
    });
  }
}
