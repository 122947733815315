import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'map',
    'emptyMap'
  ];
  static values = {
    siteMarker: String,
    workerMarker: String,
    siteLat: String,
    siteLon: String,
    workerLat: String,
    workerLon: String,

  };

  connect() {
    if (typeof(google) != "undefined") {
      this.showMap();
    }
  }

  showMap() {
    if (this.hasSiteCoordinates() || this.hasWrokerCoordinates()) {
      this.mapTarget.classList.remove('hidden');
      this.emptyMapTarget.classList.add('hidden');
      this.drawMap();
    } else {
      this.mapTarget.classList.add('hidden');
      this.emptyMapTarget.classList.remove('hidden');
    }
  }

  hasSiteCoordinates() {
    return this.siteLatValue == '' && this.siteLonValue == '' ? false : true
  }

  hasWorkerCoordinates() {
    return this.workerLatValue == '' && this.workerLonValue == '' ? false : true
  }

  getSiteCoordinates() {
    if (!this.hasSiteCoordinates()) return;

    return this.getCoordinates(this.siteLatValue, this.siteLonValue);
  }

  getWorkerCoordinates() {
    if (!this.hasWorkerCoordinates()) return;

    return this.getCoordinates(this.workerLatValue, this.workerLonValue);
  }

  drawMap() {
    let siteCoordinates = this.getSiteCoordinates();
    let workerCoordinates = this.getWorkerCoordinates();

    const map = new google.maps.Map(this.mapTarget, {
      zoom: 14,
      center: siteCoordinates || workerCoordinates,
    });

    if (siteCoordinates) {
      this.createMarker(siteCoordinates, this.siteMarkerValue, map, 'site');
    }
    if (workerCoordinates) {
      this.createMarker(workerCoordinates, this.workerMarkerValue, map, 'worker');
    }
  }

  createMarker(position, svg, map) {
    return new google.maps.Marker({
      position: position,
      animation: google.maps.Animation.DROP,
      map,
      icon: { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg), scaledSize: new google.maps.Size(40, 40) },
      optimized: false,
    });
  };

  getCoordinates(lat, lon) {
    if (!(lat && lon)) return;

    return {
      lat: parseFloat(lat),
      lng: parseFloat(lon)
    };
  }
}
