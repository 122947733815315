import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fromLabel', 'toInputGroup', 'toInputValue', 'input'];

  connect() {
    this.inputTargets.forEach(input => this.format(input));
  }

  displayRange() {
    this.fromLabelTarget.innerHTML = 'From';
    this.toInputGroupTarget.classList.toggle('invisible');
  }

  displayValue() {
    this.fromLabelTarget.innerHTML = 'Value';
    this.toInputGroupTarget.classList.toggle('invisible');
  }

  format(input) {
    const formattedValue = parseFloat(input.value).toFixed(2);
    input.value = formattedValue;
  }

  formatValue() {
    this.format(event.currentTarget);
  }
}
