import { Controller } from 'stimulus';

export default class extends Controller {
  publishScrollEvent(event) {
    if (event.type !== 'scroll') {
      console.warn(`"${event.type}" is not valid for publishScrollEvent from main-content-area`);

      return false;
    };

    PubSub.publish("mainContentAreaScroll", event);
  }
}
