import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { requiresBridge: Boolean }

  connect() {
    this.attachFileUploadClient();
  }

  attachFileUploadClient() {
    if (this.requiresBridgeValue) {
      Android.attachFileUploadClient(false);
    }
  }
}
