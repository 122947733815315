import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "siteDisplayName",
    "nextSiteAddressId",
    "modal",
    "siteAddressItem"
  ]

  initialize() {
    this.stepperLinkClass = "stepper-link";
    this.activeStepperClass = "active-stepper";
    this.hiddenClass = "hidden";
  }

  setSite(event) {
    const { value, labels } = event.target;
    const selectedSiteName = labels[0].textContent;

    this.deselectSiteAddressItems();
    this.siteDisplayNameTarget.innerHTML = selectedSiteName;
    this.updateStartShiftFormValues(this.nextSiteAddressIdTarget, value)
    this.addActiveSiteAddressToItem(event.target.parentElement);
    this.reorderShiftSiteList(value);
    this.updateSitesStatusIndicator(value);
    this.closeModal();
  }

  hideStepperIcon(siteId) {
    const stepperIcon = document.getElementById("stepper_" + siteId);
    stepperIcon.classList.add(this.hiddenClass);
  }

  showActiveStepperIcon(siteId) {
    const activeStepperIcon = document.getElementById("active_stepper_" + siteId);

    activeStepperIcon.classList.remove(this.hiddenClass);
    activeStepperIcon.classList.add(this.activeStepperClass);
  }

  turnOffPreviousActiveStepper() {
    const previousActiveStepper = document.getElementsByClassName(this.activeStepperClass)[0]
    const normalStepper = previousActiveStepper.previousElementSibling || previousActiveStepper.nextElementSibling

    previousActiveStepper.classList.remove(this.activeStepperClass);
    previousActiveStepper.classList.add(this.hiddenClass);
    if (normalStepper) {
      normalStepper.classList.remove(this.hiddenClass);
    }
  }

  closeModal() {
    const modalController = this.application.getControllerForElementAndIdentifier(this.modalTarget, "modal");
    modalController.close();
  }

  deselectSiteAddressItems() {
    this.siteAddressItemTargets.forEach(target => {
      target.classList.remove("bg-brand-tertiary-50", "border", "border-ui-200");
    })
  }

  addActiveSiteAddressToItem(elm) {
    elm.classList.add("bg-brand-tertiary-50", "border", "border-ui-200");
  }

  updateSitesStatusIndicator(value) {
    this.turnOffPreviousActiveStepper();
    this.hideStepperIcon(value);
    this.showActiveStepperIcon(value);
    this.showStepperLink(value);
    this.hideStepperLinkLastItem();
  }

  reorderShiftSiteList(seletedSiteId) {
    const siteListContainer = document.getElementById("shift-site-list-container");
    const scheduledSiteItems = siteListContainer.querySelectorAll(".scheduled-site");
    const targetSiteItem = document.getElementById("site-" + seletedSiteId);
    targetSiteItem.classList.add("animated", "fadeIn");

    siteListContainer.insertBefore(targetSiteItem, scheduledSiteItems[0])
  }

  showStepperLink(seletedSiteId) {
    const targetSiteItem = document.getElementById("site-" + seletedSiteId);
    const stepperLink = targetSiteItem.getElementsByClassName(this.stepperLinkClass)[0];
    if (stepperLink) {
      stepperLink.classList.remove(this.hiddenClass);
    }
  }

  hideStepperLinkLastItem() {
    const siteListContainer = document.getElementById("shift-site-list-container");
    const lastElementChild = siteListContainer.lastElementChild;

    lastElementChild.getElementsByClassName(this.stepperLinkClass)[0].classList.add(this.hiddenClass);
  }

  updateStartShiftFormValues(target, value) {
    target.value = value;
  }
}
