import { Controller } from "stimulus"
import { show, hide } from '../helpers/show_hide';
import { incrementNumberEaseOut } from '../../src/increment_number_ease_out';

export default class extends Controller {
  static targets = ['filterForm', 'dropdownMenu', 'filterButton']

  connect() {
    incrementNumberEaseOut('.js-slow-number');
  }

  submit() {
    Rails.fire(this.filterFormTarget, 'submit');
  }

  openDropdown() {
    show(this.dropdownMenuTarget)
  }

  hideDropdown() {
    hide(this.dropdownMenuTarget)
  }

  handleClickOutside(event) {
    if (this.dropdownMenuTarget.contains(event.target)) return;
    if (this.filterButtonTarget.contains(event.target)) return;
    this.hideDropdown()
  }
}
