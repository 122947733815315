import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'clientSelectInput',
    'siteSelectInput'
  ]

  static values = {
    getSitesByClientPath: String
  };

  handleClientChange() {
    this.fetchSiteOptions()
  }

  fetchSiteOptions() {
    this.clearSelectedSite()

    const clientId = this.clientSelectInputTarget.value

    Rails.ajax({
      url: `${this.getSitesByClientPathValue}?client_organization_id=${clientId}`,
      type: 'GET',
      dataType: 'json',
      success: (response) => {
        const sitesByClient = response?.results ?? []

        const element = document.getElementById("site-select-for-inspection-pre-starts-form")

        for (let i = 0; i < sitesByClient.length; i++) {
          let opt = document.createElement('option');
          opt.value = sitesByClient[i].id;
          opt.innerHTML = sitesByClient[i].name;
          element.appendChild(opt);
        }
      },
    });
  }

  clearSelectedSite() {
    this.siteSelectInputTarget.value = ""

    const element = document.getElementById("site-select-for-inspection-pre-starts-form")
    const chipElements = [...element.getElementsByTagName("option")]
    chipElements.forEach(chip => {
      if (chip.value) chip.remove()
    })
  }
}
