import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['overlapPopup']

  deleteOverlapPopup() {
    this.overlapPopupTarget.remove();
  }

  clickOverlapPopupBackgroud(e) {
    if (e.target.classList.contains('overlap-modal-container')) {
      this.overlapPopupTarget.remove();
    }
  }
}
