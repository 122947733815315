import { Controller } from "stimulus";
import { compressImage } from 'src/helpers';

export default class extends Controller {
  static targets = ['button', 'fileUpload', 'loading', 'imageList', 'saveButton', 'textInput'];

  initialize() {
    const activateUploadPhoto = this.data.get("activateUploadPhoto");
    if (activateUploadPhoto == 'true') {
      setTimeout(() => document.getElementById('image').click(), 500)
    }
  }

  connect() {
    this.autoScrollToError();
    this.hideLoadingState();
  }

  async handleUpload(event) {
    const attachedImages = event.target.files;
    if (!attachedImages || attachedImages.length === 0) return;

    Array.from(attachedImages).forEach(async (attachedImage) => {
      const filename = attachedImage.name;
      const newImageId = filename.replaceAll(" ", "") + Date.now().toString();

      const compressedImage = await this.compress(attachedImage);
      this.fileUploadTarget.disabled = true;
      this.showLoadingState();

      this.insertNewImage(newImageId)

      this.upload(compressedImage, filename).then((newImage) => {
        this.updateNewImageContent(newImageId, newImage);
        this.reset();
        this.hideLoadingState();
        this.toggleSaveButton()
      }).catch((error) => {
        this.addErrorMessage(error.alert)
        this.reset();
        this.removeNewImage(newImageId)
        this.hideLoadingState();
        this.toggleSaveButton();
      })
    })
  }

  compress(image) {
    const maxSize = Number(this.data.get('maxSize'));
    const maxDimension = Number(this.data.get('maxDimension'));

    const options = {
      maxSize,
      maxDimension,
    }

    return new Promise((resolve, reject) => {
      compressImage(image, options)
        .then(compressedImage => resolve(compressedImage))
        .catch(error => reject(error));
    });
  }

  upload(image, filename) {
    const url = this.data.get('url');
    const formData = new FormData();

    formData.append('image', image, filename);

    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: url,
        type: "post",
        data: formData,
        dataType: 'json',
        success: (json) => resolve(json.item),
        error: (error) => reject(error),
      });
    });
  }

  insertNewImage(id) {
    const image = `<div id=${id}>${this.data.get('loadingImage')}</div>`
    this.imageListTarget.insertAdjacentHTML('beforeend', image);
  }

  updateNewImageContent(id, content) {
    document.getElementById(id).outerHTML = String(content)
  }

  removeNewImage(id) {
    document.getElementById(id).remove()
  }

  reset() {
    this.fileUploadTarget.value = "";
    this.fileUploadTarget.disabled = false;
  }

  showLoadingState() {
    this.loadingTarget.classList.remove('hidden');
  }

  hideLoadingState() {
    this.loadingTarget.classList.add('hidden');
  }

  handleRemove(event) {
    const [data, _, { status }] = event.detail;

    if (status === 200) {
      const photoId = `photo-${data.id}`
      this.removeNewImage(photoId)
      this.toggleSaveButton()
    } else {
      this.addErrorMessage(data.error)
    }
  }

  addErrorMessage(errorMessage) {
    const messageContent = `<p class='form-hint error'>${errorMessage}</p>`
    this.imageListTarget.insertAdjacentHTML('afterend', messageContent);
  }

  toggleSaveButton() {
    if (this.textInputTarget.value || (this.imageListTarget.childElementCount > 0)) {
      this.saveButtonTarget.removeAttribute("disabled")
    } else {
      this.saveButtonTarget.setAttribute("disabled", true)
    }
  }

  autoScrollToError() {
    const errorList = document.getElementById('error-list');
    if (errorList) {
      const errorOffetTop = errorList.offsetTop
      window.scrollTo(0, errorOffetTop)
    }
  }
}
