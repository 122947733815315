import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "siteDisplayName",
    "startSiteAddressId",
    "travellingAt",
    "modal",
    "siteAddressItem"
  ]

  initialize() {
    this.siteInputName = "start_site";
    this.selectSiteOnlyElmId = "site-step-1";
    this.siteToTravellingElmId = "site-step-2";
    this.travellingToSiteKey = "travelling_to_site";
    this.travellingToSiteText = "Travelling to site";
    this.stepperLinkClass = "stepper-link";
    this.activeStepperClass = "active-stepper";
    this.hiddenClass = "hidden";
    this.activeSiteAddressClassList = ["bg-brand-tertiary-50", "border", "border-ui-200"];
    this.originalSiteListContainer = document.getElementById("shift-site-list-container").innerHTML;
  }

  setSite(event) {
    const { name, value, labels } = event.target;
    const selectedSiteName = labels[0].textContent;

    if (name == this.siteInputName) {
      this.inActiveSiteAddressItems();
      this.siteDisplayNameTarget.innerHTML = selectedSiteName;
      this.updateStartShiftFormValues(this.travellingAtTarget, "");
    }
    this.updateStartShiftFormValues(this.startSiteAddressIdTarget, value)
    this.addActiveSiteAddressToItem(event.target.parentElement);
    this.reOrderShiftSiteList(value);
    this.updateSitesStatusIndicator(value);
    this.prevStep();
    this.closeModal();
  }

  hideStepperIcon(siteId) {
    const stepperIcon = document.getElementById("stepper_" + siteId);
    stepperIcon.classList.add(this.hiddenClass);
  }

  showActiveStepperIcon(siteId) {
    const activeStepperIcon = document.getElementById("active_stepper_" + siteId);

    activeStepperIcon.classList.remove(this.hiddenClass);
    activeStepperIcon.classList.add(this.activeStepperClass);
  }

  turnOffPreviousActiveStepper() {
    const previousActiveStepper = document.getElementsByClassName(this.activeStepperClass)[0]
    const normalStepper = previousActiveStepper.previousElementSibling;

    previousActiveStepper.classList.remove(this.activeStepperClass);
    previousActiveStepper.classList.add(this.hiddenClass);
    normalStepper.classList.remove(this.hiddenClass);
  }

  setTravellingAt(event) {
    const sitesCount = Number(event.target.getAttribute("data-sites-count"));
    const defaultSiteId = event.target.getAttribute("data-default-site-id");
    const defaultInput = document.getElementById(this.travellingToSiteKey + "_" + defaultSiteId);

    this.inActiveSiteAddressItems();
    this.addActiveSiteAddressToItem(event.target.parentElement);

    if (sitesCount > 1) {
      if (this.travellingAtTarget.value.length) {
        const currentStartSite = document.getElementById('travelling_to_site_' + this.startSiteAddressIdTarget.value);

        currentStartSite.checked = true;
        this.addActiveSiteAddressToItem(currentStartSite.parentElement);
      } else {
        defaultInput.checked = true;
        this.updateStartShiftFormValues(this.startSiteAddressIdTarget, defaultSiteId);
        this.addActiveSiteAddressToItem(defaultInput.parentElement);
        this.reOrderShiftSiteList(defaultSiteId);
      }

      this.nextStep();
    } else {
      this.updateStartShiftFormValues(this.startSiteAddressIdTarget, defaultSiteId);
      this.closeModal();
    }
    this.updateStartShiftFormValues(this.travellingAtTarget, new Date());
    this.siteDisplayNameTarget.innerHTML = this.travellingToSiteText;
  }

  prevStep() {
    const selectSiteOnly = document.getElementById(this.selectSiteOnlyElmId);
    const siteToTravelling = document.getElementById(this.siteToTravellingElmId);

    selectSiteOnly.classList.remove(this.hiddenClass);
    siteToTravelling.classList.add(this.hiddenClass);
  }

  nextStep() {
    const selectSiteOnly = document.getElementById(this.selectSiteOnlyElmId);
    const siteToTravelling = document.getElementById(this.siteToTravellingElmId);

    selectSiteOnly.classList.add(this.hiddenClass);
    siteToTravelling.classList.remove(this.hiddenClass);
  }

  closeModal() {
    const modalController = this.application.getControllerForElementAndIdentifier(this.modalTarget, "modal");
    modalController.close();
  }

  inActiveSiteAddressItems() {
    this.siteAddressItemTargets.forEach(target => {
      target.classList.remove("bg-brand-tertiary-50", "border", "border-ui-200");
    })
  }

  addActiveSiteAddressToItem(elm) {
    elm.classList.add("bg-brand-tertiary-50", "border", "border-ui-200");
  }

  updateSitesStatusIndicator(value) {
    this.turnOffPreviousActiveStepper();
    this.hideStepperIcon(value);
    this.showActiveStepperIcon(value);
    this.showStepperLink(value);
    this.hideStepperLinkLastItem();
  }

  reOrderShiftSiteList(seletedSiteId) {
    this.restoreOriginalOrderShiftSiteList();

    const siteListContainer = document.getElementById("shift-site-list-container");
    const targetSiteItem = document.getElementById("site-" + seletedSiteId);
    targetSiteItem.classList.add("animated", "fadeIn");

    siteListContainer.prepend(targetSiteItem);
  }

  restoreOriginalOrderShiftSiteList() {
    let siteListContainer = document.getElementById("shift-site-list-container");
    siteListContainer.innerHTML = this.originalSiteListContainer;
  }

  showStepperLink(seletedSiteId) {
    const targetSiteItem = document.getElementById("site-" + seletedSiteId);
    targetSiteItem.getElementsByClassName(this.stepperLinkClass)[0].classList.remove(this.hiddenClass);
  }

  hideStepperLinkLastItem() {
    const siteListContainer = document.getElementById("shift-site-list-container");
    const lastElementChild = siteListContainer.lastElementChild;

    lastElementChild.getElementsByClassName(this.stepperLinkClass)[0].classList.add(this.hiddenClass);
  }

  updateStartShiftFormValues(target, value) {
    target.value = value;
  }
}
