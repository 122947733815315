import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.vhReset();
    window.addEventListener('resize', () => { this.vhReset() });
  }

  vhReset() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
