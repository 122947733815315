import { Controller } from 'stimulus';
import debounce from "lodash.debounce";

export default class extends Controller {
  connect() {
    PubSub.subscribe('mainContentAreaScroll', this.onContainerScroll.bind(this));

    this.onContainerScroll = debounce(this.onContainerScroll, 100)
  }

  onContainerScroll(e){
    if (e.currentTarget.scrollTop > 0) {
      this.element.classList.add("app-bar-top-active");
    } else {
      this.element.classList.remove("app-bar-top-active");
    }
  }
}
