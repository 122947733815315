import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { completed: Boolean }
  static classes = ['animationCompletion', 'completed']

  initialize() {
    this.connected = false
  }
  connect() {
    this.connected = true
  }
  disconnect() {
    this.connected = false
  }

  completedValueChanged() {
    if (!this.connected) return

    this.completedValue ? this.check() : this.uncheck()
  }

  toggle() {
    this.completedValue = !this.completedValue
  }

  check(){
    this.element.classList.add(this.animationCompletionClass)
    setTimeout(()=> {
      this.element.classList.add(this.completedClass)
      this.element.classList.remove(this.animationCompletionClass)
    }, 700)
  }

  uncheck(){
    this.element.classList.remove(this.completedClass)
  }
}
