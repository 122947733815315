import { minBy } from "lodash";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'calendarScrollPanel',
    'timeSlot'
  ]

  static values = {
    currentDate: String
  }

  DEFAULT_SHIFT_HEIGHT = 96;
  MARGIN_TOP_UNIT = 16;

  initialize() {
    this.scrollPositions = []
  }

  connect() {
    this.initCalendarDaily();
  }

  initCalendarDaily() {
    this.timeSlotTargets.forEach(timeSlot => {
      this.styleForTimeSlot(timeSlot);
    })

    const targetPosition = minBy(this.scrollPositions, 'left');

    if(!targetPosition) { return }

    this.calendarScrollPanelTarget.scroll(targetPosition.left, targetPosition.top);
  }

  parsedCurrentDateValue() {
    return new Date(this.currentDateValue);
  }

  styleForTimeSlot(timeSlot) {
    let children = timeSlot.children;
    let childrenCount = children.length;
    let clientHeight = `${this.DEFAULT_SHIFT_HEIGHT + (this.MARGIN_TOP_UNIT * (childrenCount - 1))}px`;
    if (childrenCount > 0) {
      this.scrollPositions.push({
        top: timeSlot.getBoundingClientRect().top,
        left: timeSlot.offsetLeft
      })
    }

    Object.assign(timeSlot.style, {
      minHeight: clientHeight
    })

    if (childrenCount > 2) {
      timeSlot.classList.remove('items-center');

    }

    this.styleForShiftCardsInTimeSlot(timeSlot);
  }

  styleForShiftCardsInTimeSlot(timeSlot) {
    let shiftElms = timeSlot.children;
    const shiftElmsCount = shiftElms.length;

    for (let i = 0; i < shiftElmsCount; i++) {
      let shiftCard = shiftElms[i];
      let shiftStartAt = new Date(shiftCard.dataset['startAt']);
      let beginSetTop = i;
      let marginTop = 'auto';
      let overlapShiftCount = parseInt(shiftCard.dataset['overlappedShiftsCount'])
      let parentElement = shiftCard.parentElement

      if (overlapShiftCount > 0) {
        beginSetTop = overlapShiftCount
      }

      marginTop = `${beginSetTop * this.MARGIN_TOP_UNIT + this.MARGIN_TOP_UNIT}px`;

      let shiftStartAtMinutes = shiftStartAt.getMinutes();
      let minutesInPercent = shiftStartAtMinutes * 100 / 60;

      if (this.parsedCurrentDateValue() > shiftStartAt) {
        minutesInPercent = 0;
      }

      let left = `${minutesInPercent}%`;

      let parentElementHeight = `${parseInt(marginTop) + this.DEFAULT_SHIFT_HEIGHT - this.MARGIN_TOP_UNIT}px`;

      if (parseInt(parentElementHeight) > parseInt(parentElement.style.minHeight)) {
        Object.assign(parentElement.style, { minHeight: parentElementHeight })
      }

      let styles = {
        left: left,
        top: marginTop
      }
      Object.assign(shiftCard.style, styles);
    }

    this.styleForLeftWorker(timeSlot);
  }

  styleForLeftWorker(timeSlot) {
    const rowParentElm = timeSlot.parentElement;
    const rowHeight = `${rowParentElm.offsetHeight}px`;
    const workerID = rowParentElm.dataset['shiftRowForWorker'];
    const workerOnLeft = document.getElementById(`worker-${workerID}`);

    if (workerOnLeft) {
      Object.assign(workerOnLeft.style, {
        minHeight: rowHeight
      })
    }
  }
}
