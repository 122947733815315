import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['index']
  static values = { sortDirection: String }

  handleSortIconClick() {
    let url = new URL(location.href);
    url.searchParams.set('sort', this.toggledSortValue());

    Rails.ajax({
      url: url.toString(),
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.indexTarget.outerHTML = data.index_component
      }
    })
  }

  toggledSortValue() {
    return this.sortDirectionValue == 'asc' ? 'desc' : 'asc';
  }
}
