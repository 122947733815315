import { Controller } from 'stimulus';
import { isEmpty } from 'lodash';

export default class extends Controller {
  static targets = [
    'questionForm',
    'saveButton',
    'isDisplayWeight',
    'labelInput',
    'inputTypeInput',
    'labelView'
  ]

  initialize() {
    this.emptyStateId = 'inspection-template-questions-empty-state'
    this.emptyStateMessage = "Add a question to start building your inspection"
  }

  addQuestion(response) {
    this.cleanUpEmptyState()

    const target = this.data.get('modifiedContentTarget')
    const incrementedOrder = this.incrementOrder(this.data.get('maxOrder'))

    const modifiedResponseDetail = response.detail[0].append_content.inspection_template_questions.replaceAll(target, incrementedOrder);
    response.detail[0].append_content.inspection_template_questions = modifiedResponseDetail;

    const domManipulationController = this.application.getControllerForElementAndIdentifier(this.element, 'dom-manipulation');
    domManipulationController.appendContent(response);

    this.data.set('maxOrder', incrementedOrder);
    if (this.saveButtonTarget.disabled === true) { this.enableSaveButton() }
  }

  removeQuestion(event) {
    const targetQuestion = event.currentTarget.parentElement;
    const questionOrder = targetQuestion.dataset.questionOrder

    if (questionOrder === this.data.get('maxOrder')) {
      targetQuestion.outerHTML = '';
      this.updateMaxOrderWithLastQuestionForm()
    } else {
      targetQuestion.outerHTML = '';
    }

    if (isEmpty(this.questionFormTargets)) {
      this.disableSaveButton()
      this.displayEmptyState()
    }
  }

  incrementOrder(maxOrder) {
    if (maxOrder === '') { return '0' };
    return (+maxOrder + 1).toString();
  }

  updateMaxOrderWithLastQuestionForm() {
    const questionForms = this.questionFormTargets;
    const updatedMaxOrder = isEmpty(questionForms) ? '' : Math.max(...questionForms.map(el => el.dataset.questionOrder));
    this.data.set('maxOrder', updatedMaxOrder);
  }

  displayEmptyState() {
    document.getElementById('inspection_template_questions').appendChild(this.createEmptyState());
  }

  createEmptyState() {
    let div = document.createElement('div');
    div.classList.add('px-4', 'py-2', 'text-center');
    div.innerHTML = this.emptyStateMessage
    div.id = this.emptyStateId
    return div;
  }

  cleanUpEmptyState() {
    const emptyStateDiv = document.getElementById(this.emptyStateId);
    if (emptyStateDiv) { emptyStateDiv.outerHTML = '' };
  }

  disableSaveButton() {
    this.saveButtonTarget.setAttribute('disabled', true)
  }

  enableSaveButton() {
    this.saveButtonTarget.removeAttribute('disabled')
  }

  show(element) {
    element.classList.remove('hidden')
  }

  hide(element) {
    element.classList.add('hidden')
  }

  isDisplayWeight() {
    return !!this.isDisplayWeightTarget.checked
  }

  toggleWeightField(e) {
    const { target } = e;
    let fieldParent = target.closest('.nested-fields');
    let weightField = fieldParent ? fieldParent.querySelector('#weight-range') : null

    if (weightField) {
      this.isDisplayWeight() ? this.show(weightField) : this.hide(weightField)
    }
  }

  updateLabelView(e) {
    let labelViewElement = this.labelViewTarget
    labelViewElement.innerText = this.labelInputTarget.value
  }

  updateInputTypeView(e) {
    const inputType = this.inputTypeInputTarget.value
    const { target } = e;
    let fieldParent = target.closest('.nested-fields');

    let ratingStarsContainer = fieldParent ? fieldParent.querySelector('#rating-stars') : null
    let binaryValuesContainer = fieldParent ? fieldParent.querySelector('#binary-values') : null

    if (inputType == 'five_star') {
      this.show(ratingStarsContainer)
      this.hide(binaryValuesContainer)
    } else {
      this.show(binaryValuesContainer)
      this.hide(ratingStarsContainer)
    }
  }
}
