import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.url = this.data.get("url");
  }

  read(event) {
    const notification_id = event.currentTarget.id;
    const data = `read_notification_id=${notification_id}`;
    Rails.ajax({
      url: this.url,
      type: 'POST',
      data: data,
    });
  }
}
