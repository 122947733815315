import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  static values = { handleSelector: String }
  static targets = ['site', 'filterInput', 'siteSearchResultsList', 'selectedSitesList', 'siteSearchResultsListEmptyState', 'sitesHeader', 'selectedHeader']
  handleInputChangeWithDebounce = debounce(this.handleInputChange, 200)

  debouncedHandleInputChange(event) {
    this.handleInputChangeWithDebounce(event)
  }

  connect() {
    this.hiddenClass = 'hidden'
    this.submitButton = document.getElementById('sites-submit-button')
    this.filterInputTarget.focus()
  }

  siteResultElements() {
    return this.siteSearchResultsListTarget.querySelectorAll("div[id^='site-checkbox-']")
  }

  selectedSiteElements() {
    return this.selectedSitesListTarget.querySelectorAll("div[id^='site-checkbox-']")
  }

  handleInputChange(event) {
    this.searchQuery = event.target.value.toLowerCase()
    this.filterSites()
    this.toggleSelectedSitesListHidden()
    this.toggleSiteSearchResultsListHidden()
  }

  handleCheckboxToggle(event) {
    const targetCheckboxElement = document.getElementById(`site-checkbox-${event.target.value}`)
    event.target.checked ? this.handleSelectSite(targetCheckboxElement) : this.handleDeselectSite(targetCheckboxElement)

    this.toggleSelectedSitesListHidden()
    this.toggleSiteSearchResultsListHidden()
    this.toggleSubmitButtonDisabled()
  }

  handleSelectSite(checkboxElement) {
    this.selectedSitesListTarget.appendChild(checkboxElement)
    const dragIcon = checkboxElement.querySelector('.drag-and-drop-handle')
    this.show(dragIcon)
  }

  handleDeselectSite(checkboxElement) {
    checkboxElement.remove()
    this.addSiteCheckboxElementToSiteSearchResultsList(checkboxElement)
    const dragIcon = checkboxElement.querySelector('.drag-and-drop-handle')
    this.hide(dragIcon)
  }

  addSiteCheckboxElementToSiteSearchResultsList(checkboxElement) {
    const unsortedCheckboxElements = [...Array.from(this.siteResultElements()), checkboxElement]
    const sortedCheckboxElements = this.sortCheckboxElementsByName(unsortedCheckboxElements)

    this.siteSearchResultsListTarget.innerHTML = ''
    sortedCheckboxElements.forEach(el => this.siteSearchResultsListTarget.appendChild(el))
  }

  sortCheckboxElementsByName(checkboxElements) {
    return checkboxElements.sort((a, b) => a.dataset.name.toLowerCase().localeCompare(b.dataset.name.toLowerCase()))
  }

  filterSites() {
    this.siteTargets.forEach((siteElement) => {
      const name = siteElement.dataset.name.toLowerCase()
      if (name.startsWith(this.searchQuery)) { return this.show(siteElement) }
      if (name.split(' ').some(word => word.startsWith(this.searchQuery))) { return this.show(siteElement) }

      this.hide(siteElement)
    })
  }

  toggleSelectedSitesListHidden() {
    if (this.selectedSitesListShouldBeHidden()) {
      this.hide(this.selectedSitesListTarget)
      this.hide(this.selectedHeaderTarget)
    } else {
      this.show(this.selectedSitesListTarget)
      this.show(this.selectedHeaderTarget)
    }
  }

  selectedSitesListShouldBeHidden() {
    const selectedSitesList = Array.from(this.selectedSiteElements())
    return selectedSitesList.length == 0 || selectedSitesList.every(el => el.classList.contains(this.hiddenClass))
  }

  toggleSiteSearchResultsListHidden() {
    if (this.siteSearchResultsListShouldBeHidden()) {
      this.hide(this.siteSearchResultsListTarget)
      if (this.searchQuery) {
        this.showSiteSearchResultsListEmptyState()
      } else {
        this.hide(this.siteSearchResultsListEmptyStateTarget)
        this.hide(this.sitesHeaderTarget)
      }
    } else {
      this.show(this.siteSearchResultsListTarget)
      this.show(this.sitesHeaderTarget)
      this.hide(this.siteSearchResultsListEmptyStateTarget)
    }
  }

  showSiteSearchResultsListEmptyState() {
    this.updateSiteSearchResultsListEmptyState()
    this.show(this.siteSearchResultsListEmptyStateTarget)
    this.show(this.sitesHeaderTarget)
  }

  siteSearchResultsListShouldBeHidden() {
    const siteResultElements = Array.from(this.siteResultElements())
    return siteResultElements.length == 0 || siteResultElements.every(el => el.classList.contains(this.hiddenClass))
  }

  toggleSubmitButtonDisabled() {
    this.selectedSiteElements().length > 0 ? this.submitButton.removeAttribute('disabled') : this.submitButton.setAttribute('disabled', true)
  }

  updateSiteSearchResultsListEmptyState() {
    const messageTarget = this.siteSearchResultsListEmptyStateTarget.querySelector('p')
    messageTarget.innerText = `No results found for '${this.searchQuery}'`
  }

  show(element) {
    element.classList.remove(this.hiddenClass)
  }

  hide(element) {
    element.classList.add(this.hiddenClass)
  }
}
