import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.time = parseInt(this.data.get('time')) || 5000;
    this.url = this.data.get('url');
    this.timeoutId = null;
  }

  connect() {
    if (this.url) this.startPolling();
  }

  disconnect(){
    if (this.url) this.stopPolling();
  }

  startPolling = () => {
    document.addEventListener("visibilitychange", this.togglePolling);
    this.scheduleUpdate();
  }

  stopPolling = () => {
    clearTimeout(this.timeoutId);
    document.removeEventListener("visibilitychange", this.togglePolling);
  }

  fetchData = () => {
    Rails.ajax({
      url: this.url,
      type: "get",
      dataType: 'script',
      success: () => {
        this.scheduleUpdate();
      },
      error: (error) => {
        console.error(error);
        this.stopPolling();
      }
    });
  }

  scheduleUpdate = () => {
    this.timeoutId = setTimeout(this.fetchData, this.time);
  }

  togglePolling = () => {
    if (document.hidden) {
      clearTimeout(this.timeoutId);
    } else  {
      this.fetchData();
    }
  }
}
