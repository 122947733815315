import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output", "slider"];

  connect() {
    const rangeValue = this.sliderTarget.value;
    this.insertValue(rangeValue);
  }

  displayValue(event) {
    const rangeValue = event.target.value;
    this.insertValue(rangeValue);
  }

  insertValue(value) {
    this.outputTarget.value = `x${value}`;
  }
}
