import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'header', 'confirmModal'];

  populateContent(event) {
    event.stopPropagation();

    const profilePath = event.currentTarget.dataset.profilePath;
    const activeTab = event.currentTarget.dataset.activeTabTarget;

    Rails.ajax({
      url: `${profilePath}?active_tab=${activeTab}`,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        this.containerTarget.innerHTML = data.profile;
        this.headerTarget.innerHTML = data.header;
        this.confirmModalTarget.innerHTML = data.confirm_modal_content;
      }
    });
  }
}
