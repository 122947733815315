import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    isSkipped: String,
    skipPath: String,
    clearAnswerPath: String,
  }

  initialize() {
    this.inspectionScoreElement = document.getElementById('inspection-score');
  }

  handleClearClick(event) {
    event.preventDefault();
    this.clearAnswer();
  }

  clearAnswer() {
    Rails.ajax({
      url: this.clearAnswerPathValue,
      type: 'PUT',
      dataType: 'json',
      success: (data) => {
        this.updateAnswerAndScores(data)
      }
    })
  }

  handleSkipClick() {
    const toggledSkipValue = this.isSkippedValue == 'false'

    Rails.ajax({
      url: this.skipPathValue,
      type: 'PUT',
      data: `is_skipped=${toggledSkipValue}`,
      dataType: 'json',
      success: (data) => {
        this.updateAnswerAndScores(data)
      }
    })
  }

  updateAnswerAndScores(data) {
    this.element.outerHTML = data.answer;
    const sectionScoreElement = document.getElementById(data.section_score_element_id)
    if (sectionScoreElement) { sectionScoreElement.innerHTML = data.section_score }
    if (this.inspectionScoreElement) { this.inspectionScoreElement.innerHTML = data.inspection_score };
  }
}
