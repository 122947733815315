
import Flatpicker from 'stimulus-flatpickr';

export default class extends Flatpicker {
  static targets = ['dateFrom', 'buttonLabel']
  initialize() {
    this.config = {
      defaultDate: this.dateFromTarget.value,
      disableMobile: true
    }
  }

  change(selectedDates) {
    this.dateFromTarget.value = null

    if (!selectedDates[0]) { return }

    this.dateFromTarget.value = selectedDates[0]
    this.element.dispatchEvent(new Event('datesChanged'))
  }
}
