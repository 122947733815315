import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form'];
  static values = {
    url: String
  }

  refreshPageWithParams() {
    const authenticityTokenHiddenInput = document.getElementsByName('authenticity_token')[0];

    this.formTarget.removeChild(authenticityTokenHiddenInput);
    this.formTarget.setAttribute("method", "get");
    this.formTarget.setAttribute("action", this.urlValue);

    this.formTarget.submit();
  }
}
