import moment from 'moment'
import {
  getCurrentMonthAndYear,
  isCurrentDate,
  isCurrentDay
} from '../../helpers/schedules_calendar'
import CalendarNavigationController from './calendar_navigation_controller'

const HEADING_INIT_CHILDREN_COUNT = 1
export default class extends CalendarNavigationController {
  static targets = [
    'month',
    'year',
    'date',
    'heading',
    'goBackBtn',
    'goForwardBtn',
    'todayBtn',
    'fromDate'
  ]
  static values = {
    datesInWeek: Array,
    url: String,
    startDateOfWeek: String,
    scrollTopOnInit: Number
  }

  scrollTopOnInit() {
    if (this.scrollTopOnInitValue) {
      let container = document.getElementById('scroll-persistence-container')
      container.scrollTop = this.scrollTopOnInitValue
    }
  }

  initialize() {
    this.isCurrentWeek = true
    super.initialize()
  }

  connect() {
    if (this.headingTarget.children.length > HEADING_INIT_CHILDREN_COUNT)
      return

    this.setMonthYear()
    this.setCalendarHeading()
    this.scrollTopOnInit()
  }

  setMonthYear() {
    const { month, year } = getCurrentMonthAndYear(this.startDateOfWeekValue)
    this.monthTarget.textContent = month
    this.yearTarget.textContent = year
  }

  setCalendarHeading() {
    this.datesInWeekValue.forEach((value) => {
      const momentDate = moment(value)
      const dateInWeek = momentDate.toDate().getDate()
      const dayHeading = this.createDayHeadingContent(dateInWeek, momentDate.format('ddd'))
      this.headingTarget.appendChild(dayHeading)
    });
  }

  createDayHeadingContent(date, day) {
    const dayHeading = document.createElement("div");
    const dayElement = this.createDayElement(day)
    const dateElement = this.createDateElement(date)

    dayHeading.classList.add("w-1/7", "flex", "flex-col", "items-center", "p-4")
    dayHeading.appendChild(dayElement)
    dayHeading.appendChild(dateElement)

    return dayHeading
  }

  createDateElement(date) {
    const dateElement = document.createElement("div")
    let dateElementClasses = "flex items-center justify-center h-12 text-headline5"

    if (isCurrentDate(date, this.isCurrentWeek))
      dateElementClasses += " w-12 rounded-full bg-brand-tertiary mx-auto"

    dateElement.classList.add(...dateElementClasses.split(" "))
    dateElement.textContent = date

    return dateElement
  }

  createDayElement(day) {
    const dayElement = document.createElement("div")
    const textColorClass = isCurrentDay(day, this.isCurrentWeek) ? "text-brand-secondary-900" : "text-secondary"
    dayElement.classList.add("text-subtitle1", textColorClass)
    dayElement.textContent = day

    return dayElement
  }
}
