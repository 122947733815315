import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['list', 'chip', 'hiddenField', 'textField']

  connect() {
    if (!this.hiddenFieldTarget.value) return;

    this.rehydrateStatesAfterRerender();
  }

  get selectedOptions() {
    if (this.data.has("selectedOptions")) {
      return JSON.parse(this.data.get("selectedOptions"))
    } else {
      return []
    }
  }

  set selectedOptions(options) {
    this.data.set("selectedOptions", JSON.stringify(options))

    this.setHiddenField(options)
    this.setChipList(options)
  }

  add(e) {
    const id = e.target.dataset.id
    const name = e.target.dataset.name
    const optionToAdd = { id: id, name: name }

    this.selectedOptions = [...this.selectedOptions, optionToAdd]

    this.clearList()

    this.textFieldTarget.value = ''
    this.textFieldTarget.focus()
  }

  clearList() {
    this.listTarget.classList.add('hidden')
    this.listTarget.innerHTML = ''
  }

  createChip(id, name, highlighted) {
    const highlightedClasses = highlighted ? ['bg-brand-secondary-200', 'border', 'border-brand-secondary-900'] : [];
    const chipClasses = ['chip-compact', 'mr-2', ...highlightedClasses];
    let removeIcon = document.createElement('i')
    removeIcon.setAttribute('data-id', id)
    removeIcon.setAttribute('data-action', 'click->scout--autocomplete#remove')
    removeIcon.classList.add('fa', 'fa-times', 'cursor-pointer', 'ml-2')

    let span = document.createElement('span')
    span.setAttribute('data-scout--autocomplete-target', 'chip')
    span.classList.add(...chipClasses)
    span.innerHTML = name
    span.appendChild(removeIcon)

    return span
  }

  createNoResults(name) {
    let div = document.createElement('div')
    div.classList.add('px-4', 'py-2')
    div.innerHTML = `No results for ${name}`
    return div
  }

  createListItem(id, name) {
    let div = document.createElement('div')
    div.setAttribute('data-id', id)
    div.setAttribute('data-name', name)
    div.setAttribute('data-action', 'click->scout--autocomplete#add')
    div.classList.add('cursor-pointer', 'hover:bg-active', 'px-4', 'py-2')
    div.innerHTML = name
    return div
  }

  displayList(e) {
    const options = this.fetchOptions()
    const name = e.target.value

    this.clearList()

    if (name === '') return

    options.forEach(option => {
      if (option.name.toLowerCase().includes(name.toLowerCase())) {
        const listItem = this.createListItem(option.id, option.name)
        this.listTarget.appendChild(listItem)
      }
    })

    if (this.listTarget.innerHTML === '') {
      this.listTarget.appendChild(this.createNoResults(name))
    }

    this.listTarget.classList.remove('hidden')
  }

  fetchOptions() {
    // options could be easily replaced with an ajax request to an endpoint that returns an array of hashes with name and id
    // [{name: 'ABC', id: '123}, {...}]
    const options = JSON.parse(this.data.get("options"))
    const selectedOptionIds = this.selectedOptions.map(option => option.id)

    return options.filter(option => !selectedOptionIds.includes(option.id))
  }

  remove(e) {
    const selectedOptions = this.selectedOptions
    const optionToRemoveIndex = selectedOptions.findIndex((option) => option.id === e.target.dataset.id)

    if (optionToRemoveIndex !== -1) {
      selectedOptions.splice(optionToRemoveIndex, 1)
      this.selectedOptions = selectedOptions
    }

    this.textFieldTarget.focus()
  }

  setChipList(options) {
    this.chipTargets.forEach(chip => chip.remove())

    options.forEach(option => {
      const chip = this.createChip(option.id, option.name, option.highlighted)
      this.textFieldTarget.insertAdjacentElement('beforebegin', chip)
    })
  }

  setHiddenField(options) {
    this.hiddenFieldTarget.value = options.map((option) => option.id)
    this.hiddenFieldTarget.dispatchEvent(new Event('change'))
  }

  rehydrateStatesAfterRerender() {
    const hiddenFieldValueList = this.hiddenFieldTarget.value.split(',');
    const options = JSON.parse(this.data.get("options"));
    const selectedOptions = options.filter(option => hiddenFieldValueList.includes(option.id));

    this.data.set("selectedOptions", JSON.stringify(selectedOptions));
    this.setChipList(selectedOptions);
  }
}
