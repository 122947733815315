import { Controller } from "stimulus"

export default class extends Controller {

  initialize(){
    this.location = this.data.get('location');
    this.action = this.data.get('action') || 'advance';
  }

  perform(){
    Turbolinks.visit(this.location, { action: this.action })
  }

}
