import { Controller } from "stimulus"

export default class extends Controller {
  turbolinks_android() {
    Android.completePrimaryOnboarding()
  }

  turbolinks_ios() {
    webkit.messageHandlers.candidateIOS.postMessage("completePrimaryOnboarding")
  }

  other() {}
}
