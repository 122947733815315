import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['resendButton']
    static values = { duration: Number }
    TIMEOUT_IN_SECONDS_DEFAULT = 15

    connect() {
        if (this.isWithinTimeout()) {
            // durationValue is 0 if the current time is same with the time of recent invitation sent
            const timeout_in_seconds = this.durationValue !== 0 ?  this.durationValue : this.TIMEOUT_IN_SECONDS_DEFAULT
            this.scheduleDisable(timeout_in_seconds)
        }
    }

    scheduleDisable(timeout_in_seconds = this.TIMEOUT_IN_SECONDS_DEFAULT) {
        this.disableResendButton()

        // disable Resend button within {timeout_in_seconds} seconds of the recent invitation sent
        setTimeout(() => this.enableResendButton(), timeout_in_seconds * 1000)
    }

    isWithinTimeout() {
        return this.durationValue < this.TIMEOUT_IN_SECONDS_DEFAULT
    }

    disableResendButton() {
        this.resendButtonTarget.setAttribute('disabled', true)
    }

    enableResendButton() {
        this.resendButtonTarget.removeAttribute('disabled')
    }
}
