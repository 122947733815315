import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['map'];
  static values = {
    parking: Object,
    site: Object,
    siteMarker: String,
    parkingMarker: String
  };

  connect() {
    if (typeof (google) != "undefined") {
      this.drawMap();
    }
  }

  drawMap() {
    let siteCoordinates = this.getCoordinates(this.siteValue);
    let parkingCoordinates = this.getCoordinates(this.parkingValue);

    const map = new google.maps.Map(this.mapTarget, {
      zoom: 14,
      center: siteCoordinates,
    });

    if (siteCoordinates) {
      this.createMarker(siteCoordinates, this.siteMarkerValue, map);
    }
    if (parkingCoordinates) {
      this.createMarker(parkingCoordinates, this.parkingMarkerValue, map);
    }
  }

  createMarker(position, svg, map) {
    new google.maps.Marker({
      position: position,
      animation: google.maps.Animation.DROP,
      map,
      icon: { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg), scaledSize: new google.maps.Size(40, 40) },
      optimized: false,
    });
  };

  getCoordinates(data) {
    if (!(data.lat && data.lon)) return;

    return {
      lat: data.lat,
      lng: data.lon
    }
  };
}
