import { Controller } from "stimulus";
import moment from 'moment';

export default class extends Controller {
  static targets = ['hours', 'minutes', 'seconds', 'activeTask', 'button'];

  initialize() {
    this.interval = 500;
    this.intervalId;
  }

  connect() {
    if (this.hasActiveTaskTarget) {
      const taskId = this.activeTaskTarget.dataset.taskId;

      this.data.set('task-id', taskId);
      this.disableButtonAtBeginning();
      this.startStopwatch();
    }
  }

  disconnect() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  disableButtonAtBeginning() {
    const activeTask = this.data.get('task-id');
    this.buttonTargets.forEach(button => {
      if (button.dataset.taskId !== activeTask) {
        button.disabled = true;
      }
    });
  }

  disableButtons(bool) {
    this.buttonTargets.forEach(button => button.disabled = bool);
  }

  handleStartButton(event) {
    const timesheetId = event.currentTarget.dataset.timesheetId;
    const taskId = event.currentTarget.dataset.taskId;

    this.disableButtons(true);

    const url = `/seeker/real_time_timesheets/${timesheetId}/tasks/${taskId}/active_task_time`;

    Rails.ajax({
      url: url,
      type: "post",
      dataType: 'json',
      success: (data) => {
        const { taskId, updatedItem, taskTimeEntryId } = data;

        this.swapItem(taskId, updatedItem, taskTimeEntryId);
        this.startStopwatch();
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  handleStopButton(event) {
    const taskTimeEntryId = event.currentTarget.dataset.taskTimeEntryId;
    const url = `/seeker/task_time_entry/${taskTimeEntryId}/completed_task_time`;

    Rails.ajax({
      url: url,
      type: "post",
      dataType: 'json',
      success: (data) => {
        const { taskId, updatedItem } = data;

        this.disableButtons(false);
        this.data.set('task-id', '');
        clearInterval(this.intervalId);
        this.swapItem(taskId, updatedItem);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  handleStoppingATask(data) {
    clearInterval(this.intervalId);

    const { taskId, updatedItem } = data;
    const selector = `[data-js-task-id="${taskId}"]`;

    document.querySelector(selector).innerHTML = updatedItem;
  }

  swapItem(taskId, updatedItem, taskTimeEntryId = null) {
    const selector = `[data-js-task-id="${taskId}"]`;

    document.querySelector(selector).innerHTML = updatedItem;

    if (taskTimeEntryId) {
      this.data.set('task-id', taskId);
    }
  }

  startStopwatch() {
    const startTimeString = this.activeTaskTarget.dataset.startTime;
    const initialDurationInMilliseconds = Number(this.activeTaskTarget.dataset.duration) * 1000;
    const startTime = moment.utc(startTimeString, 'YYYY-MM-DD HH:mm::ss').toDate();

    const options = { initialDurationInMilliseconds: initialDurationInMilliseconds, startTime: startTime };

    this.intervalId = setInterval(this.refreshStopwatch.bind(this, options), this.interval);
  }

  refreshStopwatch(options) {
    const { initialDurationInMilliseconds, startTime: initialStartTime} = options;
    const totalDuration = this.calculateTotalDuration(initialDurationInMilliseconds, initialStartTime);
    const { hours, minutes, seconds } = this.getTime(totalDuration);

    this.showTime(hours, minutes, seconds);
  }

  calculateTotalDuration(initialDurationInMilliseconds, initialStartTime) {
    const elapsedTime = Date.now() - initialStartTime;

    return initialDurationInMilliseconds + elapsedTime;
  }

  getTime(durationInMilliseconds) {
    let hours = Math.floor(durationInMilliseconds / 3600000);
    let minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((durationInMilliseconds % (1000 * 60)) / 1000);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return { hours, minutes, seconds };
  }

  showTime(hours, minutes, seconds) {
    this.hoursTarget.innerHTML = hours;
    this.minutesTarget.innerHTML = minutes;
    this.secondsTarget.innerHTML = seconds;
  }
}
