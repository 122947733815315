import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { requiresBridge: Boolean }

  connect() {
    this.requestPermissions();
  }

  requestPermissions() {
    if (this.requiresBridgeValue) {
      Android.requestLocationPermission();
    }
  }
}
