import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container'];
  static values = {
    importPath: String,
    id : String
  };

  connect() {
    const newJobImportPath = this.importPathValue;
    const jobId = this.idValue;

    Rails.ajax({
      url: `${newJobImportPath}?bullhorn_job_id=${jobId}`,
      type: 'GET',
      dataType: 'json',
    });
  }
}
