import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'endsOnDateInput',
  ];

  showEndsOnDateInput() {
    this.endsOnDateInputTarget.classList.remove('hidden');
  }

  hideEndsOnDateInput() {
    this.endsOnDateInputTarget.classList.add('hidden');
  }
}
