import moment from 'moment'
import {
  getCurrentMonthAndYear,
  createPlusIconSVG,
  isCurrentDate,
  isCurrentDay
} from '../../helpers/schedules_calendar'
import CalendarNavigationController from './calendar_navigation_controller'

const HEADING_INIT_CHILDREN_COUNT = 1
const MAX_SHIFTS_COUNT_ELEMENT_TO_DISPLAY = 3
export default class extends CalendarNavigationController {
  static targets = [
    'monthYear',
    'headingDay',
    'headingDate',
    'goBackBtn',
    'goForwardBtn',
    'todayBtn',
    'fromDate',
    'shiftCount',
    'currentSelectedDay',
    'selectedTarget'
  ]
  static values = {
    datesInWeek: Array,
    url: String,
    startDateOfWeek: String,
    shiftCount: Object
  }

  initialize() {
    this.isCurrentWeek = true
    super.initialize()
    this.setShiftCountValue()
  }

  connect() {
    if (this.headingDayTarget.children.length > HEADING_INIT_CHILDREN_COUNT)
      return

    this.setMonthYear()
    this.setCalendarHeading()
  }

  setShiftCountValue() {
    const shiftCountData = this.shiftCountTarget.dataset["seeker-Scheduling-CalendarMobileShiftCountValue"]
    this.shiftCountValue = JSON.parse(shiftCountData)
  }

  setMonthYear() {
    const { month, year } = getCurrentMonthAndYear(this.startDateOfWeekValue)
    this.monthYearTarget.textContent = `${month} ${year}`
  }

  setCalendarHeading() {
    this.datesInWeekValue.forEach((value) => {
      const momentDate = moment(value)
      const dateInWeek = momentDate.toDate().getDate()
      const day = momentDate.format('ddd')
      const dayElement = this.createDayElement(day)
      const dateElement = this.createDateElement(dateInWeek, day)
      const shiftCountElement = this.createShiftCountElements(momentDate.format("YYYY-MM-DD"))

      dateElement.appendChild(shiftCountElement)
      this.headingDateTarget.appendChild(dateElement)
      this.headingDayTarget.appendChild(dayElement)
    });
  }

  createDateElement(date, day) {
    const dateContainer = document.createElement("div")
    const dateElement = document.createElement("div")
    const isCurrentDay = isCurrentDate(date, this.isCurrentWeek)
    let dateElementClasses = "flex items-center justify-center h-8 text-subtitle1 mb-1 w-8 rounded-full mx-auto"

    if (isCurrentDay)
      dateElementClasses += ` bg-brand-tertiary`
    if (!isCurrentDay && day == this.currentSelectedDayTarget.value)
      dateElementClasses += ` bg-brand-secondary-100`

    dateElement.classList.add(...dateElementClasses.split(" "))
    dateElement.textContent = date
    dateContainer.classList.add("flex", "flex-col", "justify-center", "relative", "w-1/7", "px-1", "py-2")
    dateContainer.appendChild(dateElement)
    dateContainer.setAttribute("data-day", day)
    dateContainer.setAttribute("data-action", "click->seeker--scheduling--calendar-mobile#navigateBetweenDays")

    return dateContainer
  }

  createDayElement(day) {
    const dayElement = document.createElement("div")
    const textColorClass = isCurrentDay(day, this.isCurrentWeek) ? "text-brand-secondary-900" : "text-secondary"
    dayElement.classList.add("text-subtitle2", "text-center", "w-1/7", textColorClass)
    dayElement.textContent = day

    return dayElement
  }

  createShiftCountElements(date) {
    const shiftCountContainer = document.createElement("div")
    const plusIcon = createPlusIconSVG()

    for (let i = 1; i <= this.shiftCountValue[date]["shifts_count"]; i++) {
      if (i > MAX_SHIFTS_COUNT_ELEMENT_TO_DISPLAY) {
        shiftCountContainer.appendChild(plusIcon)
        break
      }
      const shiftCountIcon = document.createElement("div")
      shiftCountIcon.classList.add("w-1", "h-1", "bg-brand-tertiary", "rounded", "mx-0.5")
      shiftCountContainer.appendChild(shiftCountIcon)
    }

    shiftCountContainer.classList.add("flex", "justify-center")
    return shiftCountContainer
  }

  backToToday() {
    super.backToToday()
    this.currentSelectedDayTarget.value = moment().format("ddd")
  }

  navigateBetweenDays(e) {
    const selectedDay = e.currentTarget.dataset.day
    this.currentSelectedDayTarget.value = selectedDay
    Rails.fire(this.element.parentElement, "submit");
  }
}
