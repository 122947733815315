
import Flatpicker from 'stimulus-flatpickr'
export default class extends Flatpicker {
  static targets = ['dateFrom', 'dateTo', 'buttonLabel']
  initialize() {
    this.config = {
      mode: 'range',
      defaultDate: [this.dateFromTarget.value, this.dateToTarget.value],
      locale: { firstDayOfWeek: 1 } // in the future, this should be initialized with the user's locale
    }
  }

  change(selectedDates) {
    this.dateFromTarget.value = null
    this.dateToTarget.value = null

    if (!(selectedDates[0] && selectedDates[1])) { return }

    this.dateFromTarget.value = selectedDates[0]
    this.dateToTarget.value = selectedDates[1]
    this.element.dispatchEvent(new Event('datesChanged'))
  }
}
