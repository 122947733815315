import { Controller } from "stimulus"
import Turbolinks from "turbolinks";

export default class extends Controller {
  replaceContent(response) {
    if (this.isTurbolinksResponse(response)) return

    const detail = response.detail[0]
    const replaceNodeEntries = typeof detail === 'string' ? JSON.parse(detail).replace_content : detail.replace_content

    if (replaceNodeEntries == undefined)  { return }

    for (const [id, content] of Object.entries(replaceNodeEntries)) {
      const element = document.getElementById(id)
      if (element === '' || element == null) { continue; }

      element.innerHTML = content
    }
  }

  appendContent(response) {
    if (this.isTurbolinksResponse(response)) return

    const detail = response.detail[0];
    const appendContent = typeof detail === 'string' ? JSON.parse(detail).append_content : detail.append_content;

    if (appendContent == undefined)  { return }

    for (const [id, content] of Object.entries(appendContent)) {
      const element = document.getElementById(id)
      if (element === '' || element === null) { continue; }

      element.insertAdjacentHTML('beforeend', content);
    }
  }

  replaceNodes(response) {
    if (this.isTurbolinksResponse(response)) return

    const detail = response.detail[0]
    const replaceNodeEntries = typeof detail === 'string' ? JSON.parse(detail).replace_nodes : detail.replace_nodes

    if (replaceNodeEntries == undefined)  { return }

    for (const [id, content] of Object.entries(replaceNodeEntries)) {
      const element = document.getElementById(id)
      if (element === '' || element == null) { continue; }

      element.outerHTML = content
    }
  }

  removeNodes(response) {
    if (this.isTurbolinksResponse(response)) return

    const detail = response.detail[0]
    const removeNodeIds = typeof detail === 'string' ? JSON.parse(detail).remove_nodes : detail.remove_nodes

    if (removeNodeIds == undefined)  { return }

    for (const id of removeNodeIds) {
      const element = document.getElementById(id)
      if (element === '' || element == null) { continue; }

      element.remove()
    }
  }

  isTurbolinksResponse(response) {
    return /Turbolinks.visit|Turbolinks.clearCache/.test(response.detail[0])
  }
}
