import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.activeClasses = ['active-target', 'bg-brand-tertiary-50']
  }

  toggleActiveClass(e) {
    const currentTarget = e.currentTarget
    if(currentTarget.classList.contains("active-target")) {
      this.removeActiveClasses(currentTarget)
    }
    else {
      this.addActiveClassesTo(currentTarget)
    }
  }

  addActiveClassesTo(target) {
    const previousActiveTarget = document.getElementsByClassName('active-target')[0]
    if(previousActiveTarget) {
      this.removeActiveClasses(previousActiveTarget)
    }

    target.classList.add(...this.activeClasses)
  }

  removeActiveClasses(target) {
    target.classList.remove(...this.activeClasses)
  }
}
