import { Controller } from 'stimulus';
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = ['entries', 'pagination', 'counter'];

  connect() {
    PubSub.subscribe('mainContentAreaScroll', this.onContainerScroll);

    this.onContainerScroll = debounce(this.onContainerScroll, 200)
  }

  get nextPageUrl() {
    if (!this.hasPaginationTarget) { return; }

    const nextPage = this.paginationTarget.querySelector("a[rel='next']");

    if (!nextPage) { return null; }

    return nextPage.href;
  }

  onContainerScroll = (event) => {
    if (!this.nextPageUrl) return;

    if (this.reachTheEndOfContainer(event.target)) {
      this.loadMore(this.nextPageUrl);
    }
  }

  reachTheEndOfContainer(container) {
    return container.scrollTop + container.offsetHeight >= container.scrollHeight;
  }

  loadMore(nextPageUrl) {
    Rails.ajax({
      type: 'GET',
      url: nextPageUrl,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination;

        if (this.hasCounterTarget) {
          this.counterTarget.innerHTML = data.counter;
        }
      }
    });
  }
}
