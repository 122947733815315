import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    this.flashClasses = {
      'notice': ['flash-active'],
      'alert': ['flash-active', 'flash-shake'],
      'bottom_36': 'bottom-36'
    }
    this.flashClassesForType = this.flashClasses[this.data.get('type')];
    this.flashExtraClass = this.flashClasses[this.data.get('extraClass')];
  }

  connect() {
    const flashType = this.data.get('type')
    setTimeout(() => this.show(), 300)
    if (flashType === 'notice') {
      setTimeout(() => this.hide(), 5000)
    }
  }

  show() {
    this.element.classList.add(...this.flashClassesForType, this.flashExtraClass)
  }

  hide() {
    this.element.classList.remove(...this.flashClassesForType, this.flashExtraClass)
    setTimeout(() => this.destroy(), 1000)
  }

  destroy() {
    if (!this.element.parentNode) return

    this.element.parentNode.removeChild(this.element);
  }

}
