import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['editable', 'textarea', 'confirm', 'edit', 'cancel'];

  initialize() {
    this.currentValue;
  }

  connect() {
    this.reset();
  }

  handleEdit() {
    const value = this.editableTarget.textContent.trim();

    this.startEditMode();

    this.textareaTarget.focus();
    this.textareaTarget.value = value;
    this.currentValue = value;
  }

  confirm(event) {
    const newValue = this.textareaTarget.value;
    if (this.currentValue === newValue) {
      this.reset();

      return;
    };

    this.disable(true);

    const url = event.target.dataset.url;
    const formData = new FormData();

    formData.append('timesheet_image[label]', newValue);

    this.updateText(url, formData)
      .then(result => {
        this.disable(false);

        this.editableTarget.textContent = result;

        this.reset();
      })
      .catch(error => {
        console.error(error);
        this.disable(false);
      });
  }

  updateText(url, formData) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: url,
        type: "put",
        data: formData,
        dataType: 'json',
        success: (json) => resolve(json.label),
        error: (error) => reject(error),
      });
    });
  }

  startEditMode() {
    this.editTarget.classList.add('hidden');
    this.editableTarget.classList.add('hidden');
    this.textareaTarget.classList.remove('hidden');
    this.confirmTarget.classList.remove('hidden');
    this.cancelTarget.classList.remove('hidden');
  }

  reset() {
    this.editTarget.classList.remove('hidden');
    this.editableTarget.classList.remove('hidden');
    this.textareaTarget.classList.add('hidden');
    this.confirmTarget.classList.add('hidden');
    this.cancelTarget.classList.add('hidden');
  }

  disable(bool) {
    this.textareaTarget.disabled = bool;
    this.confirmTarget.disabled = bool;
    this.cancelTarget.disabled = bool;
  }
}
