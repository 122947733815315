import { Controller } from "stimulus"
import { useEditShift } from "../mixins/use_edit_shift"

export default class extends Controller {
  static targets = [
    'finishDate',
    'isOverNight',
    'startDateInput',
    'finishDateInput',
    'startTimeInput',
    'finishTimeInput',
  ]

  DATE_FORMAT = 'YYYY-MM-DD'
  TIME_FORMAT = 'HH-mm'

  connect() {
    useEditShift(this)
  }
}
