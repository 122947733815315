import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    requiresBridge: Boolean,
    timesheetId: String
  }

  connect() {
    this.geotagTimesheet();
  }

  geotagTimesheet() {
    const TIMESHEET_OBJECT_TYPE = "timesheet"

    if (this.requiresBridgeValue && this.hasTimesheetIdValue) {
      Android.geotagObject(TIMESHEET_OBJECT_TYPE, this.timesheetIdValue);
    }
  }
}
