import { Controller } from "stimulus"
import linkifyElement from 'linkify-element';

export default class extends Controller {
  initialize() {
    const OPTIONS = {
      className: "text-link",
      target: '_blank',
    }

    linkifyElement(this.element, OPTIONS);
  }
}
