import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    handleSelector: String,
    useCallback: Boolean
  }
  static targets = [ "list" ]

  connect() {
    new window.Sortable(this.listTarget, {
      handle: this.handleSelectorValue,
      animation: 250,
      easing: "cubic-bezier(0.33, 1, 0.68, 1)",
      chosenClass: "drag-and-drop-chosen-class",
      dragClass: "drag-and-drop-drag-class",
      ghostClass: "drag-and-drop-ghost-class",
      ...this.initOptions()
    })
  }

  initOptions() {
    let options = {};
    if (this.useCallbackValue) {
      options.onEnd = (event) => { this.updateItemIndex(event.newIndex, event.clone.dataset.reorderUrl) }
    }

    return options
  }

  updateItemIndex(newIndex, reorderUrl) {
    /* Rails.ajax({
      url: reorderUrl,
      type: 'PUT',
      data: `new_index=${newIndex}`
    }) */
  }
}
