import { Controller } from 'stimulus';

export default class extends Controller {
  finishPlacement(event) {
    const finishedPlacementPath = event.currentTarget.dataset.finishedPlacementPath

    Rails.ajax({
      url: finishedPlacementPath,
      type: 'POST',
    });
  }
}
