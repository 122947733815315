import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container'];

  initialize() {
    this.isOpen = false;
    this.openClass = 'is-open';
    this.currentCallerId = null;
    this.unactiveClasses = ['hover:bg-ui-25', 'bg-surface']
    this.activeClasses = ['active-target', 'bg-brand-tertiary-50', 'hover:bg-brand-tertiary-100']
  }

  disconnect() {
    this.close()
  }

  open(callerId) {
    this.containerTarget.classList.add(this.openClass);
    this.currentCallerId = callerId;
    this.isOpen = true;
  }

  close() {
    this.containerTarget.classList.remove(this.openClass);
    this.currentCallerId = null;
    this.isOpen = false;

  }

  closePanel() {
    this.close();
    PubSub.publish('slidingPanelRightClosed');
  }

  toggle(e) {
    const callerId = e.currentTarget.id;

    if (!this.currentCallerId) {
      this.open(callerId);
      return;
    } else if (callerId === this.currentCallerId) {
      this.close();
      return;
    }
    this.currentCallerId = callerId;
  }
}
