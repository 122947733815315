import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['item', 'container'];

  initialize() {
    this.highlightedClasses = this.containerTarget.dataset.highlightedClasses.split(' ');
    this.notHighlightedClasses = this.containerTarget.dataset.nothighlightedClasses.split(' ');
    this.highlightedItemId = null;
    PubSub.subscribe('candidateSearchResultRefresh', this.rehighlightCurrentItem.bind(this));
    PubSub.subscribe('slidingPanelRightClosed', this.unhighlightCurrentItem.bind(this));
  }

  highlightItem(item) {
    item.classList.remove(...this.notHighlightedClasses);
    item.classList.add(...this.highlightedClasses);
    this.highlightedItemId = item.id;
  }

  unhighlight(item) {
    item.classList.remove(...this.highlightedClasses);
    item.classList.add(...this.notHighlightedClasses);
    this.highlightedItemId = null;
  }

  rehighlightCurrentItem() {
    const currentItem = this.itemTargets.find(t => t.id === this.highlightedItemId);
    if (currentItem) {
      this.highlightItem(currentItem);
    }
  }

  unhighlightCurrentItem() {
    const currentItem = this.itemTargets.find(t => t.id === this.highlightedItemId);
    if (currentItem) {
      this.unhighlight(currentItem);
    }
    this.highlightedItemId = null;
  }

  toggle() {
    const item = this.itemTargets.find(t => t.id === event.currentTarget.id);
    const currentItem = this.itemTargets.find(t => t.id === this.highlightedItemId);

    if (!currentItem) {
      this.highlightItem(item);
      return;

    } else if (item.id === currentItem.id) {
      this.unhighlight(currentItem);
      return;

    }
    this.unhighlight(currentItem);
    this.highlightItem(item);
  }
}
