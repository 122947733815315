import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { path: String }

  handleRowClick(e) {
    e.stopPropagation()
    Turbolinks.visit(this.pathValue);
  }

  onKebabMenuClicked(e) {
    e.stopPropagation();
  }
}
