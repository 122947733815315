import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container', 'toggle', 'searchForm', 'searchInput']

  disconnect() {
    this.close();
  }

  open() {
    this.containerTarget.classList.remove(this.data.get("class"));

    if (!this.hasSearchInputTarget) return;
    this.searchInputTarget.value = '';
    this.searchInputTarget.focus();
    Rails.fire(this.searchFormTarget, 'submit');
  }

  close() {
    this.containerTarget.classList.add(this.data.get("class"))
  }

  handleClickOutside(event) {
    if (this.containerTarget.contains(event.target)) return;
    if (this.toggleTarget.contains(event.target)) return;

    this.close()
  }
}
