import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'id', 'loader', 'skill', 'toggle' ];
  static values = {
    url: String,
    clearAll : String,
    selectAll : String
  };

  connect() {
    if (!this.hasSkillTarget) {
      this.submit();
    }
  }

  decode = (string) => string.replace(/\+/g,' ');

  toggle() {
    const checkedPresent = this.skillTargets.map(skillTarget => skillTarget.checked).find(checked => checked);
    if (checkedPresent) {
      this.skillTargets.forEach(skillTarget => skillTarget.checked = false);
      this.toggleTarget.innerHTML = this.decode(this.selectAllValue);
    } else {
      this.skillTargets.forEach(skillTarget => skillTarget.checked = true);
      this.toggleTarget.innerHTML = this.decode(this.clearAllValue);
    }
    this.toggleTarget.blur();
  }

  showLoader() {
    this.loaderTarget.classList.remove('hidden');
  }

  submit() {
    this.showLoader();
    let params = new URLSearchParams()
    params.append(this.idTarget.name, this.idTarget.value);
    Rails.ajax({
      url: `${this.urlValue}?${params}`,
      type: 'POST',
      dataType: 'json'
    });
  }
}
