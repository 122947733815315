import { Controller } from "stimulus"
import { show, hide } from '../../helpers/show_hide';
import { weekOfTheMonth } from '../../../src/date';
import moment from "moment"

const FLEXIBLE_DATE_INPUT_DIV_ID = "flexible_shift_date_time"
const FIXED_DATE_INPUT_DIV = "fixed_shift_date_time"
const TIME_FORMAT = 'HH-mm'
const DATE_FORMAT = 'YYYY-MM-DD'

export default class extends Controller {
  static targets = [
    'dailyRecurrenceRuleFields',
    'weeklyRecurrenceRuleFields',
    'monthlyRecurrenceRuleFields',
    'daysOfTheWeek',
    'finishDateInput',
    'flexibleFinishTimeInput',
    'flexibleStartTimeInput',
    'flexibleStartDateInput',
    'flexibleFinishDateInput',
    'isOvernight',
    'startDateInput',
    'finishDate',
    'finishDateInput',
    'endsOnDateInput',
    'startTimeInput',
    'finishTimeInput',
    'ruleTypeSelect',
    'flexibleDurationHours',
    'flexibleDurationMinutes',
    'flexibleShiftDescriptionContainer'
  ]

  static values = {
    ruleTypeOptionsUrl: String,
    isRecurringShift: Boolean
  }

  DAILY = 'daily';
  WEEKLY = 'weekly';
  MONTHLY = 'monthly';
  DATE_FORMAT = 'YYYY-MM-DD';
  TIME_FORMAT = 'HH-mm';
  SHORT_DAY_NAME_FORMAT = "ddd";
  SCHEDULE_DURATION_LIMIT_HOURS = 24;

  initialize() {
    this.flexible = this.data.get("flexible");
  }

  connect() {
    this.setDefaultStartDateAndTimeLabel()
    this.setFinishDate()
  }

  handleRuleTypeSelect(event) {
    const ruleType = event.target.value.toLowerCase();
    const isFlexibleShift = !!document.getElementById('flexible_shift').checked;
    this.toggleFlexibleInformation();

    switch (ruleType) {
      case this.DAILY:
        show(this.dailyRecurrenceRuleFieldsTarget);
        hide(this.weeklyRecurrenceRuleFieldsTarget);
        hide(this.monthlyRecurrenceRuleFieldsTarget);
        hide(this.daysOfTheWeekTarget);
        break;
      case this.WEEKLY:
        show(this.weeklyRecurrenceRuleFieldsTarget);
        hide(this.dailyRecurrenceRuleFieldsTarget);
        hide(this.monthlyRecurrenceRuleFieldsTarget);
        if (this.daysOfTheWeekTarget && !isFlexibleShift) {
          show(this.daysOfTheWeekTarget);
        }
        this.handleDisplayWeekDaysForFlexibleForm();
        break;
      case this.MONTHLY:
        hide(this.dailyRecurrenceRuleFieldsTarget);
        hide(this.weeklyRecurrenceRuleFieldsTarget);
        show(this.monthlyRecurrenceRuleFieldsTarget);
        hide(this.daysOfTheWeekTarget);
        break;
    }
  }

  toggleBetweenShiftTypeForm() {
    this.setFinishDate()

    document.getElementById(FLEXIBLE_DATE_INPUT_DIV_ID).classList.toggle('hidden')
    document.getElementById(FIXED_DATE_INPUT_DIV).classList.toggle('hidden')
    this.toggleFlexibleShiftDescriptionContainer()

    if (document.getElementById('rule_type_select') != null) {
      this.toggleDaysOfTheWeekTargetForFlexibleShift()
    }
  }

  toggleDaysOfTheWeekTargetForFlexibleShift() {
    if (document.getElementById('flexible_shift').checked) {
      this.handleDisplayWeekDaysForFlexibleForm();
      this.toggleFlexibleInformation();
    }
    else if (document.getElementById('rule_type_select').querySelector('option[value=weekly]').selected) {
      this.daysOfTheWeekTarget.classList.remove('hidden')
    }
  }

  toggleShiftTypeOnEditForm() {
    const flexibleShiftButton = document.getElementById('flexible_shift')
    const fixedShiftButton = document.getElementById('fixed_shift')

    if (flexibleShiftButton.checked) {
      fixedShiftButton.checked = true
    }
    else {
      flexibleShiftButton.checked = true
    }
  }

  isFlexible() {
    return document.getElementById('flexible_shift').checked
  }

  adjustTime(element, time, amount = 0) {
    if (!time) return;
    if (!element._flatpickr) return;
    element._flatpickr.setDate(moment(time, 'hh:mm').add(amount, 'hour').format(TIME_FORMAT))
  }

  setMaxDateOnCalendar(element, startTime) {
    if (!element._flatpickr || !element._flatpickr.config) {
      return setTimeout(() => {
        this.setMaxDateOnCalendar(element, startTime)
      }, 1000)
    }
    const ruleTypeSelect = this.ruleTypeSelectTarget.value;
    let maxFinishDate = moment(startTime).add(7, 'day').format(DATE_FORMAT);

    if (ruleTypeSelect == this.MONTHLY) {
      element._flatpickr.set("maxDate", null);
    } else {
      element._flatpickr.set("maxDate", maxFinishDate);
    }
  }

  adjustFinishTime() {
    const startTime = this.isFlexible() ? this.flexibleStartTimeInputTarget.value : this.startTimeInputTarget.value
    const finishTimeTarget = this.isFlexible() ? this.flexibleFinishTimeInputTarget : this.finishTimeInputTarget

    this.adjustTime(finishTimeTarget, startTime, 1)
  }

  setDefaultStartDateAndTimeLabel() {
    if (this.isRecurringShiftValue) {
      this.handleDisplayWeekDaysForFlexibleForm();
    }
    this.toggleFlexibleInformation();
  }

  setDefaultStartingDate() {
    const defaultStartDateElement = document.getElementById('flexible_start_date_text')
    if (!defaultStartDateElement) return;

    const date = new Date(this.flexibleStartDateInputTarget.value)
    const formattedDate = moment(date).format("dddd DD MMMM")

    defaultStartDateElement.innerHTML = formattedDate
  }

  getFormattedFlexibleStartTime() {
    return moment(this.flexibleStartTimeInputTarget.value, 'hh:mm').format('hh:mm A')
  }

  getFormattedFlexibleFinishTime() {
    return moment(this.flexibleFinishTimeInputTarget.value, 'hh:mm').format('hh:mm A')
  }

  setDefaultStartingTime() {
    this.toggleFlexibleInformation();
  }

  handleStartDateChange() {
    this.setFinishDate()
    this.adjustRecurringTypeOptions()
    this.setDefaultStartingDate()
    this.handleDisplayWeekDaysForFlexibleForm();
    this.toggleFlexibleInformation();
  }

  handleFlexibleFinishDateChange() {
    this.handleDisplayWeekDaysForFlexibleForm();
    this.toggleFlexibleInformation();
  }

  handleFlexibleFinishTimeChange() {
    this.handleDisplayWeekDaysForFlexibleForm();
    this.toggleFlexibleInformation();
  }

  handleDayOfWeekChange() {
    this.toggleFlexibleInformation();
  }

  adjustRecurringTypeOptions() {
    if (!this.isRecurringShiftValue) return;
    const searchParams = new URLSearchParams()
    const startDate = this.isFlexible() ? this.flexibleStartDateInputTarget.value : this.startDateInputTarget.value

    searchParams.append(`selected_date`, startDate);
    searchParams.append(`selected_option`, this.ruleTypeSelectTarget.value);

    Rails.ajax({
      url: `${this.ruleTypeOptionsUrlValue}?${searchParams}`,
      type: 'GET',
      success: (data) => {
        this.ruleTypeSelectTarget.innerHTML = data.content
        if(this.isFlexible()) {
          this.disableDaily();
        }
      }
    });
  }

  setIsOvernight() {
    const startTimeValue = this.isFlexible() ? this.flexibleStartTimeInputTarget.value : this.startTimeInputTarget.value
    const finishTimeValue = this.isFlexible() ? this.flexibleFinishTimeInputTarget.value : this.finishTimeInputTarget.value
    const startTime = moment(startTimeValue, 'HH::mm')
    const finishTime = moment(finishTimeValue, 'HH::mm')

    this.isOvernightTarget.checked = finishTime < startTime
  }

  setFinishDate() {
    const isOvernightShift = this.isShiftOverNight();
    if (this.isFlexible() && this.isRecurringShiftValue) {
      let startDate = this.getFlexibleStartDate();
      this.setMaxDateOnCalendarForWeekly()
      this.adjustDate(this.flexibleFinishDateInputTarget, startDate, 0);

    } else if (isOvernightShift) {
      let startDate = this.startDateInputTarget.value
      let amount = isOvernightShift ? 1 : 0
      this.adjustDate(this.finishDateInputTarget, startDate, amount)
    }
  }

  isShiftOverNight() {
    return !!this.isOvernightTarget.checked
  }

  adjustDate(element, date, amount = 0) {
    if (!date) return;
    if (!element._flatpickr) return;

    element._flatpickr.setDate(moment(date).add(amount, 'day').format(DATE_FORMAT))
  }

  handleDoesEndOnDateToggle(event) {
    event.target.value == 'true' ? show(this.endsOnDateInputTarget) : hide(this.endsOnDateInputTarget)
  }

  disableDaily() {
    const dailyOptionElement = document.getElementById('daily_recurrence_rule_fields')
    const weeklyOptionElement = document.getElementById('weekly_recurrence_rule_fields')
    const monthlyOptionElement = document.getElementById('monthly_recurrence_rule_fields')
    const dailyOption = document.querySelector("#rule_type_select option[value='daily']")
    const weeklyOption = document.querySelector("#rule_type_select option[value='weekly']")

    if (this.flexibleStartDateInputTarget.value == this.flexibleFinishDateInputTarget.value) {
      dailyOption.disabled = false
    }
    else {
      show(weeklyOptionElement);
      hide(dailyOptionElement);
      hide(monthlyOptionElement);

      if (this.ruleTypeSelectTarget.value == this.DAILY) {
        weeklyOption.selected = true;
      }
      dailyOption.disabled = true;
      dailyOption.selected = false;
      this.toggleFlexibleInformation();
    }
  }

  getFlexibleStartDate() {
    return this.flexibleStartDateInputTarget.value;
  }

  getFlexibleStartTime() {
    return this.flexibleStartTimeInputTarget.value;
  }

  getFlexibleFinishDate() {
    return this.flexibleFinishDateInputTarget.value;
  }

  getFlexibleFinishTime() {
    return this.flexibleFinishTimeInputTarget.value;
  }

  getFlexibleStartDateTime() {
    return new Date(`${this.getFlexibleStartDate()} ${this.getFlexibleStartTime()}`);
  }

  getFlexibleFinishDateTime() {
    return new Date(`${this.getFlexibleFinishDate()} ${this.getFlexibleFinishTime()}`);
  }

  scheduleDurationAsHours() {
    let startTime = moment(this.getFlexibleStartDateTime());
    let endTime = moment(this.getFlexibleFinishDateTime());
    let duration = moment.duration(endTime.diff(startTime));
    return duration.asHours();
  }

  approximateDurationAsHours() {
    const durationHours = this.flexibleDurationHoursTarget.value;
    const durationMinutes = this.flexibleDurationMinutesTarget.value;
    let duration = [];

    if (durationHours == 1) {
      duration.push(`${durationHours} hour`)
    } else if (durationHours > 1) {
      duration.push(`${durationHours} hours`)
    }

    if (durationMinutes == 1) {
      duration.push(`${durationMinutes} minute`)
    } else if (durationMinutes > 1) {
      duration.push(`${durationMinutes} minutes`)
    }

    return duration.join(' and ')
  }

  isFlexibleTimeOverLimitDuration() {
    if (this.scheduleDurationAsHours() < 0) return false;
    return this.scheduleDurationAsHours() > this.SCHEDULE_DURATION_LIMIT_HOURS;
  }

  handleDisplayWeekDaysForFlexibleForm() {
    if (!this.isRecurringShiftValue) return false;
    const ruleTypeSelect = this.ruleTypeSelectTarget.value;
    const isFlexibleShift = !!document.getElementById('flexible_shift').checked;

    if (ruleTypeSelect != this.WEEKLY || !isFlexibleShift) return;
    if (this.isFlexibleTimeOverLimitDuration()) {
      hide(this.daysOfTheWeekTarget);
    } else {
      show(this.daysOfTheWeekTarget);
    }
  }

  getSelectedWeekDays() {
    const weekDayInputs = document.querySelectorAll("input[identifier='forms_scout_client_organizations_draft_recurring_shifts_day_of_week']:checked");
    const chosenWeekDays = Array.from(weekDayInputs).map(day => day.dataset['dayName']);
    return chosenWeekDays;
  }

  toggleFlexibleShiftDescriptionContainer() {
    this.flexibleShiftDescriptionContainerTarget.classList.contains('hidden') ? this.showFlexibleShiftDescriptionContainer() : this.hideFlexibleShiftDescriptionContainer()
  }

  hideFlexibleShiftDescriptionContainer() {
    hide(this.flexibleShiftDescriptionContainerTarget);
  }

  showFlexibleShiftDescriptionContainer() {
    show(this.flexibleShiftDescriptionContainerTarget);
  }

  flexibleDailyStartingMessage() {
    return `1 shift daily anytime after ${this.getFormattedFlexibleStartTime()} - before ${this.getFormattedFlexibleFinishTime()} for ${this.approximateDurationAsHours()}`
  }

  flexibleWeeklyStartingTimeInDayMessage() {
    const chosenWeekDays = this.getSelectedWeekDays();
    if (chosenWeekDays.length) {
      return `${chosenWeekDays.length} shifts weekly on ${chosenWeekDays.join(', ')} anytime after ${this.getFormattedFlexibleStartTime()} - before ${this.getFormattedFlexibleFinishTime()} for ${this.approximateDurationAsHours()}`;
    } else {
      return "";
    }
  }

  flexibleWeeklyStartingTimeOverDayMessage() {
    const startShortDay = moment(this.getFlexibleStartDate()).format(this.SHORT_DAY_NAME_FORMAT);
    const finishShortDay = moment(this.getFlexibleFinishDate()).format(this.SHORT_DAY_NAME_FORMAT);

    return `1 shift weekly anytime after ${startShortDay}, ${this.getFormattedFlexibleStartTime()} - before ${finishShortDay}, ${this.getFormattedFlexibleFinishTime()} for ${this.approximateDurationAsHours()}`
  }

  flexibleMonthlyStartingTimeInDayMessage() {
    const weekOfTheMonthName = weekOfTheMonth(this.getFlexibleStartDateTime());

    return `1 shift monthly on the ${weekOfTheMonthName} anytime after ${this.getFormattedFlexibleStartTime()} - before ${this.getFormattedFlexibleFinishTime()} for ${this.approximateDurationAsHours()}`
  }

  flexibleMonthlyStartingTimeOverDayMessage() {
    const startTime = weekOfTheMonth(this.getFlexibleStartDateTime());
    const weekOfTheMonthName = weekOfTheMonth(this.getFlexibleFinishDateTime());

    return `1 shift monthly on the ${startTime} anytime after ${this.getFormattedFlexibleStartTime()} - before the ${weekOfTheMonthName}, ${this.getFormattedFlexibleFinishTime()} for ${this.approximateDurationAsHours()}`
  }

  flexibleInformationMessageForAdhocForm() {
    const startFormattedDateTime = moment(this.getFlexibleStartDateTime()).format("ddd D MMM, hh:mm A");
    const finishFormattedDateTime = moment(this.getFlexibleFinishDateTime()).format("ddd D MMM, hh:mm A");

    if (this.isSameDay()) {
      return `Flexible shift anytime after ${startFormattedDateTime} - before  ${this.getFormattedFlexibleFinishTime()} for ${this.approximateDurationAsHours()}`
    } else {
      return `Flexible shift anytime after ${startFormattedDateTime} - before  ${finishFormattedDateTime} for ${this.approximateDurationAsHours()}`
    }
  }

  displayDefaultStartingTime() {
    let messageContainer = document.getElementById("starting-time-information");
    messageContainer.innerHTML = this.defaultStartingTimeMessage()
  }

  displayFlexibleDailyStartingTime() {
    let messageContainer = document.getElementById("starting-time-information");
    messageContainer.innerHTML = this.flexibleDailyStartingMessage();
  }

  displayFlexibleWeeklyStartingTime() {
    this.showFlexibleShiftDescriptionContainer();
    let messageContainer = document.getElementById("starting-time-information");

    if (this.isFlexibleTimeOverLimitDuration()) {
      messageContainer.innerHTML = this.flexibleWeeklyStartingTimeOverDayMessage();
    } else {
      const message = this.flexibleWeeklyStartingTimeInDayMessage();
      if (message.length) {
        messageContainer.innerHTML = this.flexibleWeeklyStartingTimeInDayMessage();
      } else {
        this.hideFlexibleShiftDescriptionContainer();
      }
    }
  }

  displayFlexibleMonthlyStartingTime() {
    let messageContainer = document.getElementById("starting-time-information");
    if (this.isFlexibleTimeOverLimitDuration()) {
      messageContainer.innerHTML = this.flexibleMonthlyStartingTimeOverDayMessage();
    } else {
      messageContainer.innerHTML = this.flexibleMonthlyStartingTimeInDayMessage();
    }
  }

  isSameDay() {
    return moment(this.getFlexibleStartDate()).isSame(this.getFlexibleFinishDate(), 'day')
  }

  displayFlexibleInformationMessageForAdhocForm() {
    let messageContainer = document.getElementById("starting-time-information");
    messageContainer.innerHTML = this.flexibleInformationMessageForAdhocForm();
  }

  toggleFlexibleInformationMessageForRecurringForm() {
    const isFlexibleShift = !!document.getElementById('flexible_shift').checked;

    if (isFlexibleShift) {
      const ruleTypeSelect = this.ruleTypeSelectTarget.value;
      this.showFlexibleShiftDescriptionContainer()
      switch (ruleTypeSelect) {
        case this.DAILY:
          this.displayFlexibleDailyStartingTime();
          break;
        case this.WEEKLY:
          this.displayFlexibleWeeklyStartingTime();
          break;
        case this.MONTHLY:
          this.displayFlexibleMonthlyStartingTime();
          break;
        default:
          this.displayFlexibleDailyStartingTime();
      }
    } else {
      this.hideFlexibleShiftDescriptionContainer();
    }
  }

  toggleFlexibleInformation() {
    if (this.isRecurringShiftValue) {
      this.toggleFlexibleInformationMessageForRecurringForm();
      if (this.isFlexible()) {
        this.setMaxDateOnCalendarForWeekly();
      }
    } else {
      this.displayFlexibleInformationMessageForAdhocForm();
    }
  }

  setMaxDateOnCalendarForWeekly() {
    let startDate = this.getFlexibleStartDate();
    this.setMaxDateOnCalendar(this.flexibleFinishDateInputTarget, startDate);
  }
}
