import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['content'];

  hide() {
    this.contentTarget.classList.add(this.data.get("class"));
  }

  show() {
    this.contentTarget.classList.remove(this.data.get("class"));
  }

}
