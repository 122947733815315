import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['mainCheckbox', 'checkbox', 'countLabel', 'action', 'form']

  get count() {
    if (this.data.has("count")) {
      return parseInt(this.data.get("count"))
    } else {
      return 0
    }
  }

  set count(value) {
    this.data.set("count", value)
    this.countLabelTarget.innerHTML = `${value} selected`

    const count = parseInt(value)
    this.toggleAction(count)
    this.setMainCheckbox(count)
  }

  setMainCheckbox(count) {
    if (count === 0) {
      this.mainCheckboxTarget.indeterminate = false
      this.mainCheckboxTarget.checked = false
    } else if (count === this.checkboxTargets.length) {
      this.mainCheckboxTarget.indeterminate = false
      this.mainCheckboxTarget.checked = true
    } else {
      this.mainCheckboxTarget.indeterminate = true
    }
  }

  toggleAction(count) {
    if (count === 0) {
      this.actionTargets.forEach(action => {
        action.classList.add(this.data.get("class"))
      })
    } else {
      this.actionTargets.forEach(action => {
        action.classList.remove(this.data.get("class"))
      })
    }
  }

  toggleAll(e) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = e.target.checked
    })

    if (e.target.checked) {
      this.checkboxTargets.forEach(checkbox => {
        this.appendInput(checkbox.value)
      })
      this.count = this.checkboxTargets.length
    } else {
      this.checkboxTargets.forEach(checkbox => {
        this.removeInput(checkbox.value)
      })
      this.count = 0
    }
  }

  toggle(e) {
    const id = e.target.value

    if (e.target.checked) {
      this.appendInput(id)
      this.count++
    } else {
      this.removeInput(id)
      this.count--
    }
  }

  appendInput(id) {
    if (this.hasFormTarget) {
      this.formTargets.forEach(form => {
        const inputName = form.dataset.selectionInputName
        const existingInput = form.querySelector(`input[type='hidden'][name='${inputName}[]'][value='${id}']`)
        if (!existingInput) {
          let newInput = document.createElement('input')
          newInput.type = 'hidden'
          newInput.name = `${inputName}[]`
          newInput.multiple = true
          newInput.value = id

          form.appendChild(newInput)
        }
      })
    }
  }

  removeInput(id) {
    if (this.hasFormTarget) {
      this.formTargets.forEach(form => {
        const inputName = form.dataset.selectionInputName
        let input = document.querySelector(`input[type='hidden'][name='${inputName}[]'][value='${id}']`)
        if (input) {
          form.removeChild(input)
        }
      })
    }
  }
}
