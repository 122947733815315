import { Controller } from 'stimulus';
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = ['entries', 'pagination'];

  initialize() {
    this.scroll = debounce(this.scroll, 200)
  }

  scroll() {
    if (!this.hasPaginationTarget) { return; }
    const nextPage = this.paginationTarget.querySelector("a[rel='next']");
    if (!nextPage) { return; }

    const nextPageUrl = nextPage.href;
    const body = document.body, html = document.documentElement;

    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    // could use offset from height - window.innerHeight to trigger before bottom
    if (window.pageYOffset >= height - window.innerHeight) {
      this.loadMore(nextPageUrl);
    }
  }

  loadMore(nextPageUrl) {
    Rails.ajax({
      type: 'GET',
      url: nextPageUrl,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination;
      }
    });
  }
}
