import { Controller } from "stimulus"

export default class extends Controller {
  addField(e) {
    e.preventDefault();

    const { target } = e;
    let time = new Date().getTime();
    let linkId = target.dataset.id;
    let regexp = linkId ? new RegExp(linkId, 'g') : null;
    let newFields = regexp ? target.dataset.fields.replace(regexp, time) : null
    // Add the new markup to the form if there are fields to add.
    newFields ? target.previousElementSibling.insertAdjacentHTML('beforeend', newFields) : null
  }

  removeField(e) {
    e.preventDefault();
    const { target } = e;
    let fieldParent = target.closest('.nested-fields');
    let destroyField = fieldParent ? fieldParent.querySelector('.js-destroy') : null
    if (destroyField) {
      destroyField.value = 1;
      fieldParent.style.display = 'none'
    }
  }

  show(element) {
    element.classList.remove('hidden')
  }

  hide(element) {
    element.classList.add('hidden')
  }

  switchToReadMode(e) {
    e.preventDefault();
    this.switchView(e, '#edit-question', '#read-question')
  }

  switchToEditMode(e) {
    e.preventDefault();
    this.switchView(e, '#read-question', '#edit-question')
  }

  switchView(e, hidden_container_id, display_container_id) {
    const { target } = e;
    let fieldParent = target.closest('.nested-fields');
    let hiddenField = fieldParent ? fieldParent.querySelector(hidden_container_id) : null
    let displayField = fieldParent ? fieldParent.querySelector(display_container_id) : null

    if (hiddenField && displayField) {
      this.show(displayField)
      this.hide(hiddenField)
    }
  }
}
