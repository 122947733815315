import { Controller } from 'stimulus';
import { debounce, isEmpty, sortBy } from 'lodash';

export default class extends Controller {
  static targets = ['container', 'checkboxes', 'searchInput', 'checkbox', 'checkboxInput'];

  displayCheckboxesWithDebounce = debounce(this.displayCheckboxes, 200);

  initialize() {
    this.hiddenClass = 'hidden';
    this.noResultsId = "multiselect-filter-no-results";
  }

  get allCheckboxes() {
    return sortBy(this.checkboxTargets, (checkbox) => [!checkbox.getElementsByTagName('input')[0].checked, checkbox.dataset.labelText]);
  };

  setCheckboxesContainerHeight() {
    const container = this.containerTarget;
    const searchInput = this.searchInputTarget;
    const checkboxes = this.checkboxesTarget;

    const containerHeight = container.offsetHeight;
    const searchInputHeight = searchInput.offsetHeight;

    const newHeight = containerHeight - searchInputHeight;
    checkboxes.style.height = `${newHeight}px`;
  }

  clearAll() {
    this.checkboxInputTargets.forEach((checkbox) => checkbox.checked = false);
  }

  debouncedDisplayCheckboxes(event) {
    this.displayCheckboxesWithDebounce(event);
  }

  displayCheckboxes(event) {
    const searchTerm = event.target.value.toLocaleLowerCase();

    if (isEmpty(searchTerm)) this.allCheckboxes.forEach(checkbox => this.showCheckbox(checkbox));

    const filteredCheckboxes = searchTerm.length == 1
      ? this.filterByFirstLetter({ letter: searchTerm, checkboxes: this.allCheckboxes })
      : this.filterByText({ searchTerm, checkboxes: this.allCheckboxes });

    this.cleanUpNoResults();
    if (isEmpty(filteredCheckboxes)) { this.displayNoResult(searchTerm) };

    this.renderVisibleCheckboxes({ filteredCheckboxes });
  }

  renderVisibleCheckboxes({ filteredCheckboxes }) {
    this.allCheckboxes.forEach((checkbox) => {
      filteredCheckboxes.includes(checkbox) ? this.showCheckbox(checkbox) : this.hideCheckbox(checkbox)
      this.checkboxesTarget.appendChild(checkbox);
    });
  }

  filterByText({ searchTerm, checkboxes }) {
    return checkboxes.filter(checkbox => checkbox.dataset.labelText.includes(searchTerm));
  };

  filterByFirstLetter({ letter, checkboxes }) {
    return checkboxes.filter(checkbox => checkbox.dataset.labelText[0] === letter);
  };

  showCheckbox(checkbox) {
    checkbox.classList.remove(this.hiddenClass);
  };

  hideCheckbox(checkbox) {
    checkbox.classList.add(this.hiddenClass);
  };

  cleanUpNoResults() {
    if (!!document.getElementById(this.noResultsId)) { document.getElementById(this.noResultsId).outerHTML = '' };
  }

  displayNoResult(searchTerm) {
    this.checkboxesTarget.appendChild(this.createNoResult(searchTerm));
  }

  createNoResult(searchTerm) {
    let div = document.createElement('div');
    div.classList.add('px-4', 'py-2');
    div.innerHTML = `${this.data.get('noResultMessage')} "${searchTerm}"`;
    div.id = this.noResultsId;
    return div;
  }
}
